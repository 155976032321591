<template>
  <div class="loading">
    <img src="/img/loading-svg/loading-bars.svg" alt="">
  </div>
</template>
<script>
  export default{
    name:'loading'
  }
</script>
<style lang="scss">
  .loading{
    left:700px;
    z-index: 10;
    position: absolute;
    height:180px;
    line-height:180px;
    text-align:center;
    padding:30px 0;
    img{
      height:100%;
    }
  }
</style>