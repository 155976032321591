<template>
  <div class="scan">
    <div class="mask"></div>
    <div class="item-wrap">
      <div class="img-scan"></div>
      <div class="img-pay">
        <div class="title">微信支付<em @click="close"></em></div>
        <div class="qrcodeImg"><img class="codeImg" :src="img" /></div>
        <div class="tip">
          <p>请使用<span class="theme-color">微信</span>扫一扫</p>
          <p>二维码完成支付</p>
          <div class="modal-footer" style="margin-top:10px;">
          <a href="javascript:;" class="btn"  v-on:click="$emit('submit')">确定支付</a>
          <a href="javascript:;" class="btn btn-default" v-on:click="$emit('cancel')">取消</a>
        </div>
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "scan-pay-code",
  props: ["img"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import "./../assets/scss/button.scss";
.scan {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.6;
    background-color: #000000;
    z-index:100;
  }
  .item-wrap {
    position: fixed;
    z-index: 101;
    width: 670px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    .img-scan {
      @include bgImg(373px, 525px, "./../assets/images/pay/icon-scan.png");
    }
    .img-pay {
      width: 370px;
      height: 440px;
      display: inline-block;
      background-color: #ffffff;
      .title {
        position: relative;
        height: 60px;
        line-height: 60px;
        color: #333333;
        font-size: 20px;
        padding: 0 18px;
        background-color: #f5f5f5;
        em {
          position: absolute;
          top: 23.5px;
          right: 20px;
          @include bgImg(13px, 13px, "/img/icon-close.png");
          cursor: pointer;
        }
      }
      .qrcodeImg {
        text-align: center;

        .codeImg {
          width: 237px;
          height: 240px;
          z-index: 10;
        }
      }
      .tip {
        text-align: center;
        font-size: 14px;
        color: #333333;
      }
    }
  }
}
</style>