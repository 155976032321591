<template>
  <div class="my">
    <el-card>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item></el-breadcrumb-item>
      </el-breadcrumb>
      <div class="module">
        <p class="" style="padding-top: -20px">
          <span class="module-title-name font26">我的余额:</span>
          <span
            class="group-span Danger font26 priceColor"
            style="margin-left: 20px"
            >￥{{ user | handleNum }} 元</span
          >
        </p>
        <div class="charge" >
          <div v-for="(item, index) in ruleList" :key="index">
            <el-card
              @click.native="changeIndex(index)"
              shadow="always"
              class="chargeNum"
              :class="checkIndex == index ? 'chargeNumCheck' : ''"
            >
              <div>充 {{ item.chargeAmount }} 送 {{ item.givingAmount }}</div>
            </el-card>
          </div>
          <el-card
              @click.native="changeIndex(999)"
              shadow="always"
              class="chargeNum"
              :class="checkIndex == 999 ? 'chargeNumCheck' : ''"
            >
              <div>自定义</div>
            </el-card>
            <el-input style="width:190px;margin-top:35px;margin-left:20px;" v-model="typeInValue" v-show="inputAmount" placeholder="请输入充值金额"></el-input>
        </div>
        <loading v-if="loading"></loading>
        <div class="item-pay">
          <span class="priceColor">支付金额:￥{{ Money }} 元</span>
          <h3></h3>

          <div class="pay-way">
            <p>付款方式</p>
            <div class="flex">
              <div
                class="pay pay-ali"
                :class="{ checked: payType == 1 }"
                @click="isRea1name(1)"
              ></div>
              <div
                @click="isRea1name(2)"
                class="pay pay-wechat"
                :class="{ checked: payType == 2 }"
              ></div>
              <div
                class="group-span Danger font26 priceColor"
                style="margin-left: auto; margin-right: 40px; padding-top: 60px"
              >
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal
        :showSure="showSkip"
        title="实名认证"
        btnType="3"
        :showModal="showSkip"
        cancelText="取消"
        @cancel="goCancel"
        @submit="goRealname"
      >
        <template v-slot:body>
          <p style="font-size: 18px">{{ s }}秒后跳转到实名认证页面</p>
        </template>
      </modal>
    </el-card>
    <modal
      title="支付确认"
      btnType="3"
      :showModal="showPayModal"
      sureText="已支付"
      cancelText="未支付"
      @cancel="showPayModal = false"
      @submit="checkOrder"
    >
      <template v-slot:body>
        <p>您确认是否完成支付？</p>
      </template>
    </modal>
    <modal
      title="去支付"
      btnType="3"
      :showModal="showAlipay"
      sureText="确认"
      cancelText="取消"
      @cancel="showAlipay = false"
      @submit="sureAlipay"
    >
      <template v-slot:body>
        <p>您确认要支付吗？</p>
      </template>
    </modal>

    <weixin-pay-code
      title="微信支付"
      scan="微信支付"
      v-if="showWeixinPay"
      @close="showWeixinPay = false"
      @submit="sureWeixinPay"
      @cancel="showWeixinPay = false"
      :img="code_url"
    ></weixin-pay-code>
  </div>
</template>

<script>
// @ is an alias to /src
import Loading from "./../components/loading.vue";
import QRCode from "qrcode";
import { mapState, mapActions } from "vuex";
import Modal from "./../components/Modal.vue";
import WeixinPayCode from "./../components/weixinPayCode";
export default {
  components: { Loading, Modal, WeixinPayCode },
  name: "index",
  created() {
    this.getPayRule();
    let token = window.localStorage.getItem("token");
    if (token) {
      this.updateUser();
    }
  },
  data() {
    return {
      showWeixinPay:false,
      showAlipay: false,
      input: "",
      mgyChargeRulesPageParam: {
        pageIndex: 1,
        pageSize: 100,
      },
      ruleList: [{ chargeAmount: 0 }],
      checkIndex: 0,
      mgyChargeRecordParam: { chargeAmount: "", type: 0 },
      payType: 1,
      outTradeNo: "",
      showPayModal: false,
      loading: false,
      showSkip: false,
      setTime: null,
      s: 5,
      alipayInfo: {},
      code_url:'',
      wxOrderNo:'',
      inputAmount:false,
      typeInValue:''
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    Money() {
      if(this.checkIndex==999){
        return this.typeInValue;
      }
      if (this.ruleList.length > 0) {
        return (
          Math.round(this.ruleList[this.checkIndex].chargeAmount * 100) / 100
        );
      } else {
        return "";
      }
    },
  },
  filters: {
    handleNum(val) {
      if (val) {
        return val.userBalance.toFixed(2);
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions(["updateUser"]),
    getPayRule() {
      this.$http
        .post("/mgyChargeRules/getPageList", this.mgyChargeRulesPageParam)
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.ruleList = res.data.data.records;
          } else {
            // this.$message.error("服务错误");
          }
        });
    },
    changeIndex(e) {
      this.checkIndex = e;
      if(e==999){
        this.inputAmount=true;
      }
      else{
        this.inputAmount=false;
      }
    },
    chargeBalance() {
      this.loading = true;
      this.mgyChargeRecordParam.type = this.payType;
      if(this.inputAmount==true){
        this.mgyChargeRecordParam.chargeAmount =this.typeInValue;
      }else{
        this.mgyChargeRecordParam.chargeAmount =
        this.ruleList[this.checkIndex].chargeAmount;
      }
      console.log(this.mgyChargeRecordParam);
      this.$http
        .post("/mgyChargeRecord/balanceCharge", this.mgyChargeRecordParam)
        .then((res) => {
          this.loading = false;
          console.log(res);
          if (res.data.code == 200) {
            this.alipayInfo = res.data.data;
            this.showAlipay = true;
          } else {
            this.$message.error(res.data.data);
          }
        });
    },
    checkOrder() {
      this.showPayModal = false;
      this.$http
        .post(`/payBack/aliPayCallBack/${this.outTradeNo}`)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("支付成功");
            this.updateUser();
          } else {
            this.$message.error("支付失败");
          }
        });
    },
    isRea1name(e) {
      this.payType = e;
      if (this.user.isReal) {
        if (e == 1) {
          this.chargeBalance();
        } else if (e == 2) {
          this.goWeixinPay();
        }
      } else {
        this.showSkip = true;
        this.setTime = setInterval(() => {
          this.countDown();
        }, 1000);
      }
    },
    countDown() {
      if (this.s > 0) {
        this.s -= 1;
      } else if (this.s == 0) {
        clearInterval(this.setTime);
        return this.$router.push({ name: "realName" });
      }
    },
    goCancel() {
      this.showSkip = false;
      clearInterval(this.setTime);
    },
    sureAlipay() {
      this.showAlipay = false;
      this.outTradeNo = this.alipayInfo.outTradeNo;
      let routeData = this.$router.resolve({
        name: "alipay",
        query: { content: this.alipayInfo.body },
      });
      window.open(routeData.href, "_blank");
      this.showPayModal = true;
    },
    goWeixinPay() {
      this.loading = true;
      this.mgyChargeRecordParam.type = this.payType;
      if(this.inputAmount==true){
        this.mgyChargeRecordParam.chargeAmount =this.typeInValue;
      }else{
        this.mgyChargeRecordParam.chargeAmount =
        this.ruleList[this.checkIndex].chargeAmount;
      }
      console.log(this.mgyChargeRecordParam);
      this.$http
        .post("/mgyChargeRecord/balanceCharge", this.mgyChargeRecordParam)
        .then((res) => {
          this.loading = false;
          console.log(res);
          if (res.data.code == 200) {
              this.wxOrderNo =  res.data.data.orderNo
            this.getCode(res.data.data.code_url)
          } else {
            this.$message.error(res.data.data);
          }
        });
    },
    getCode(e) {
      QRCode.toDataURL(e).then((url) => {
         this.showWeixinPay = true;
          this.code_url = url;
      });
    },
    //微信支付回调
    sureWeixinPay(){
      this.showWeixinPay = false
      console.log(this.wxOrderNo)
      this.$http.get(`/payBack/wxPaySearchOrder/${this.wxOrderNo}`).then(res => {
        if(res.data.code == 200){
          this.$message.success(res.data.message)
          this.updateUser()
        }else if(res.data.code == 500){
          this.$message.error(res.data.data)
        }else{
          console.log('bad')
        }
      })
    },
    goRealname(){
      this.$router.push('/realName')
    }
  },
};
</script>
<style lang="scss">
.my {
  .item-pay {
    padding: 26px 0px 72px;
    margin-left: 0;
    background-color: #ffffff;
    color: #333333;
    h3 {
      font-size: 18px;
      font-weight: 400;
      color: #666666;
      padding-bottom: 24px;
      border-bottom: 1px solid #d7d7d7;
      margin-bottom: 26px;
    }
    .pay-way {
      font-size: 18px;
      color: #666666;
      .pay {
        display: inline-block;
        width: 188px;
        height: 64px;
        border: 1px solid #d7d7d7;
        cursor: pointer;
        &:last-child {
          margin-left: 20px;
        }
        &.checked {
          border: 1px solid #ff6700;
        }
      }
      .pay-ali {
        background: url("../assets/images/pay/icon-ali.png") no-repeat center;
        background-size: 103px 38px;
        margin-top: 19px;
      }
      .pay-wechat {
        background: url("../assets/images/pay/icon-wechat.png") no-repeat center;
        background-size: 103px 38px;
      }
    }
  }
  .font26 {
    font-size: 18px;
    color: #666666;
  }

  .priceColor {
    color: #ff8500;
    font-size: 18px;
  }
  .charge {
    flex-wrap:wrap;
    width: 100%;
    margin-left: -20px;
    display: flex;
    .chargeNumCheck {
      color: #fff !important;
      background-color: #059fff;
    }
    .chargeNum {
      margin-top:20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 60px;
      margin-left: 20px;
      cursor: pointer;
      border: 1px solid #ccc;
      font-size: 16px;
      color: #333;
    }
    .lastNum {
      border-right: 1px solid #ccc;
    }
  }
}
</style>
