<template>
  <div class="scan">
    <div class="mask"></div>
    <div class="item-wrap">
      <div class="img-pay">
        <div class="title">{{title}}<em @click="close"></em></div>
        <div class="qrcodeImg"><img class="codeImg"  :src="img"/></div>
        <div class="tip">
          <p>请使用<span class="theme-color">{{scan}}</span>扫一扫</p>
           <div class="btn-group" >
            <a href="javascript:;" class="btn" v-on:click="$emit('submit')">已认证</a>
            <a href="javascript:;" class="btn btn-default" v-on:click="$emit('cancel')">取消</a>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    name:'scan-pay-code',
    props:{
      img:String,
      title:{
        type:String,
        default:'微信支付'
      },
      scan:{
        type:String,
        default:'支付宝'
      },

    },
    methods:{
      close(){
        this.$emit('close');
      }
    }
  }
</script>
<style lang="scss" scoped>

  @import './../assets/scss/config.scss';
  @import './../assets/scss/mixin.scss';
  @import './../assets/scss/button.scss';
  .btn-group{
  margin-right: 20px;
  margin-top:20px;
  .btn-default{
    margin-left:40px !important;
  }
}
  .scan{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .mask{
      z-index:100;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .6;
      background-color: #000000;
    }
    .item-wrap{
       z-index: 101;
      position: fixed;
      width:370px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      display: flex;
      align-items: center;
      .img-scan{
        @include bgImg(273px,485px,'./../assets/images/pay/alipay.webp');
      }
      .img-pay{
        width: 370px;
        height: 440px;
        display: inline-block;
        background-color:#ffffff;
        .title{
          position:relative;
          height:60px;
          line-height:60px;
          color:#333333;
          font-size:20px;
          padding:0 18px;
          background-color:#F5F5F5;
          em{
            position:absolute;
            top: 23.5px;
            right: 20px;
            @include bgImg(13px,13px,'/img/icon-close.png');
            cursor:pointer;
          }
        }
        .qrcodeImg{
          text-align: center;
          padding:44px 0 0px;
          .codeImg{
            width:237px;
            height:240px;
            z-index: 10;
          }
        }
        .tip{
          text-align:center;
          font-size:14px;
          color:#333333;
        }
      }
    }
  }
</style>