<template>
  <div class="home">
    <router-view></router-view>
    <nav-footer></nav-footer>
    <connect></connect>
  </div>
</template>

<script>
import Connect from './../components/connect.vue'
import NavFooter from './../components/nav-footer.vue'
export default {
  name: 'home',
  components: {
    NavFooter,
    Connect
  }
}
</script>

