<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>
  export default {
	components: {

	},
	name: 'app',
	// 依赖注入
	provide(){
		return {
			app:this
		}
	},
	data() {
		return {

		}
	},
	created() {
		// 初始化用户信息
		 this.$store.commit('initUser')

	},
	methods: {

	},
}
</script>
<style>

@import './assets/css/animate.min.css';
@import './assets/css/common.css';
@import './assets/css/index.css';
@import './assets/css/front.css';
</style>
