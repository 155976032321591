<template>
  <div class="">
    <!-- banner begin -->
    <nav-header></nav-header>
    <!--banner-->
    <!--banner-->
<div class="security-banner">
  <div class="container">
    <div class="header-info">
      <h1>百分服务，助您上云无忧</h1>
      <p>匠心打造完整的VIP会员服务体系，为国内国际用户提供多种服务支持和服务保障让<br>用户尊享售后服务，让云端部署更轻松、更高效</p>
      <a href="/register/">立即注册</a>
    </div>
  </div>
</div>
<div class="cloumn-blank"></div>
<!--栏目-->
<div class="security-cloumn">
  <ul>
    <li class="active" data-obj="scoll2">10倍故障赔偿</li>
    <li data-obj="scoll3">5天无理由退款</li>    <li data-obj="scoll4">7X24小时多渠道服务支持</li>
    <li data-obj="scoll5">VIP多对1服务</li>
  </ul>
</div>
<!--100倍故障赔偿-->
<div class="security-compensation scoll2" id="part2">
  <div class="container">
    <h1 class="security-title">10倍故障赔偿<span>蘑菇云体验站承诺由于蘑菇云体验站故障导致产品无法正常使用，我们将提供10倍的故障时间赔偿让您使用舒心</span></h1>
    <ul class="security-list">
      <li class="item1"><p></p><h5>联系客服/提交工单</h5></li>
      <li class="item2"><p></p><h5>技术排查定位问题</h5></li>
      <li class="item3"><p></p><h5>快速解决故障</h5></li>
      <li class="item4"><p></p><h5>核实赔偿范围</h5></li>
      <li class="item5"><p></p><h5>赔偿10倍故障时间</h5></li>
    </ul>
    <div class="security-stype">
      <h3>赔偿类型</h3>
      <ul>
        <li class="item1"><h4>故障赔偿</h4><p>蘑菇云体验站平台原因导致您的云服务器、云虚拟主机、服务器托管完全不可访问，不可正常使用</p></li>
        <li class="item2"><h4>非故障类不可补偿</h4><p>用户因操作或被攻击导致的云产品不能访问、IP被封停的情况，不可补偿</p></li>
        <li class="item3"><h4>运营商故障不可补偿</h4><p>由于运营商故障导致的丢包和延时，不可抗力、意外事件导致的损失不在赔偿范围内</p></li>
      </ul>
    </div>
    <p class="security-btns"><a href="" class="btn1" style="display: none;">详细赔偿规则查看</a><a href="" class="btn2" style="display: none;">我要索赔</a></p>
  </div>
</div>
<!--5天无理由退款-->
<div class="security-refund scoll3" id="part3">
  <div class="container">
    <h1 class="security-title">5天无理由退款<span>蘑菇云体验站承诺用户购买云服务器5天内无条件退款， 致力为您打造更优良的服务器体验环境。购买蘑菇云体验站服务器，我们承诺5天无理由全额退款</span></h1>
    <ul class="security-list">
      <li class="item1"><p></p><h5>提交退款申请</h5></li>
      <li class="item2"><p></p><h5>审核确定</h5></li>
      <li class="item3"><p></p><h5>退款至账余额</h5></li>
      <li class="item4"><p></p><h5>重新购买</h5></li>
    </ul>
    <p class="security-btns"><a href="" class="btn1" style="display: none;">查看详细退款规则</a><a href="/user/refund/server" class="btn2">我要退款</a></p>
    <div class="security-problems js_problems2">
      <h3>退款常见问题</h3>
      <dl class="active">
        <dt>5天无理由退款条件</dt>
        <dd>
          <h4>5天无理由退款条件</h4>
          <p>①、新购5天内可申请无理由退款；</p>
          <p>②、每个用户每月云服务器限最多退款1次，每月最多累积可退1台；每个用户云虚拟主机限每月最多退款1次，每月最多累积可退1台；</p>
          <p>③、未签订合同及申请发票；</p>
          <p>④、部分活动产品不支持5天无理由退款（详情请见活动规则）。</p>
        </dd>
      </dl>
      <dl>
        <dt>退款流程</dt>
        <dd>
          <h4>退款流程</h4>
          <p>用户中心提交退款申请 > 系统审核通过 > 款项退至您的蘑菇云体验站账户，您可以直接前往用户中心查看。</p>
        </dd>
      </dl>
      <dl>
        <dt>续费、升级订单可以申请退款吗？</dt>
        <dd>
          <h4>续费、升级订单可以申请退款吗？</h4>
          <p>您好，续费及升级订单为子订单，不可申请退款；如主购买订单符合5天内无理由退款规则，可申请一并退款；</p>
        </dd>
      </dl>
    </div>
  </div>
</div>
<!--7X24小时多渠道服务支持-->
<div class="security-support scoll4" id="part4">
  <div class="container">
    <h1 class="security-title">7X24小时多渠道服务支持<span>专业的售后工程师团队为您提供 7*24*365 技术服务，在您使用蘑菇云体验站产品期间遇到任何问题，您可及时<a href="/user/workorder/" target="_blank">提交工单</a>或
	    <a href="http://crm2.qq.com/page/portalpage/wpa.php?uin=499247925&aty=0&a=0&curl=&ty=1"  target="_blank">咨询在线QQ</a>
			    或<em>拨打18914771295</em>为您服务</span></h1>
    <div class="security-stype">
      <ul>
        <li class="item1"><h4>售前服务</h4><p>售前咨询团队将竭诚为您提供云产品咨询、解决方案及其它服务</p></li>
        <li class="item2"><h4>售中服务</h4><p>为您配备专业的架构师团队，一对一提供全程架构指导，保障业务快速上云</p></li>
        <li class="item3"><h4>售后服务</h4><p>专业的技术支持7*24小时待命，充分保障您产品的无忧使用和业务的稳定运行</p></li>
      </ul>
    </div>
  </div>
</div>
<!--VIP多对1服务-->
<div class="security-vip scoll5" id="part5">
  <div class="container">
    <h1 class="security-title">VIP多对1服务<span>为了进一步提供更细致、更专业的服务，蘑菇云体验站针对VIP客户，我司将通过建立微信群/QQ讨论组/电话等形式为您安排多人的技术团队为您提供售前售后的服务</span></h1>
    <dl>
      <dt></dt>
      <dd>
        <h4>微信讨论组</h4>
        <p>针对VIP客户，为了进一步提供更细致、专业的服务，我司通过建立微信或QQ讨论组随时随地为您服务，我司将安排主管及以上级别负责人员与贵方人员一起对接完成售前、售中、售后服务，为您提供最优质最舒心的服务。</p>
      </dd>
    </dl>
    <ul>
      <li class="item1">
	      <a href="http://crm2.qq.com/page/portalpage/wpa.php?uin=499247925&aty=0&a=0&curl=&ty=1" target="_blank">QQ联系</a>
		            </li>
      <li class="item2"><a>咨询热线：<span>
      			18914771295
      </span></a></li>
      <li class="item3"><a>上门服务</a></li>
    </ul>
  </div>
</div>
  </div>
</template>
<script type="text/javascript" src="../assets/js/jquery_nicescroll.js"  ></script>
<script type="text/javascript" src="../assets/js/jquery.min.js"  ></script>
<script>
import NavHeader from "./../components/nav-header.vue";
export default {
  name: "security",
  components: { NavHeader },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style scoped src="../assets/css/security.css" >

</style>