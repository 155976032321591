<template>
  <div>
    <nav-header></nav-header>

    <div class="hosting-banner">
      <div class="header-info">
        <h1>云虚拟主机</h1>
        <p>
          稳定、安全、弹性、高性能的云端计算服务，实时满足您的多样性业务需求
        </p>
      </div>
    </div>
    <div class="hostColor">
      <!-- <div class="trusteeship-info"></div> -->
      <!-- 推荐套餐 -->
      <div class="module recommend-package">
        <p class="module-title">
          <span class="module-title-name">云虚拟主机</span>
          <span class="group-span"></span>
        </p>
        <ul class="host-type-1">
          <li>
            <div class="name">
              <p>{{ productList.productTitle }}</p>
              <span>{{ productList.productSubTitle }}</span>
            </div>
            <div class="host-config" style="width: 880px">
              <ul>
                <li>
                  <p>硬盘</p>
                  <p>{{ productList.productSsd }}</p>
                </li>
                <li>
                  <p>CPU</p>
                  <p>{{ productList.productCpu }}</p>
                </li>
                <li>
                  <p>月流量</p>
                  <p>不限</p>
                </li>
                <li>
                  <p>内存</p>
                  <p>{{ productList.productMemory }}</p>
                </li>
                <li>
                  <p>带宽</p>
                  <p>{{ productList.productBandwidth }}</p>
                </li>
              </ul>
            </div>
            <div class="line"></div>
          </li>
        </ul>
        <div class="config-tier" style="margin-top: 20px">
          <div
            class="
              config-tier-title config-tier-title-4
              default-transition-tree-fast
            "
          >
            <span> 购买时长 </span>
          </div>
          <div class="config-tier-panel buying-time">
            <div class="config-row clearfix">
              <span class="config-row-title config-row-title-shorter"
                >购买时长：</span
              >
              <div class="flex">
                <div
                  class="month"
                  v-for="(item, index) in productSkuList"
                  :key="index"
                  :class="index == productSkuList.length - 1 ? 'lastNum' : ''"
                >
                  <div
                    @click="change(item, index)"
                    :class="monthIndex == index ? 'chooseColor' : ''"
                    class="chooseMonth"
                  >
                    {{ item.showTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="config-tier" style="margin-top: 20px" v-if="type == 0">
          <div
            class="
              config-tier-title config-tier-title-4
              default-transition-tree-fast
            "
          >
            <span> 选择镜像 </span>
          </div>
          <div class="config-tier-panel buying-time">
            <div class="config-row clearfix">
              <span class="config-row-title config-row-title-shorter"
                >选择镜像:</span
              >
              <div class="flex">
                <div
                  :class="index == mirrorList.length - 1 ? 'lastNum' : ''"
                  class="mirror"
                  v-for="(item, index) in mirrorList"
                  :key="index"
                >
                  <div
                    @click="chooseMirror(item, index)"
                    :class="mirrorIndex == index ? 'chooseColor' : ''"
                    class="chooseMirror"
                  >
                    {{item.mirrorName}}
                  </div>
                </div>
              </div>
              <div class="config-row clearfix flex">
                <span class="config-row-title config-row-title-shorter"
                  >用户名</span
                >
                <span class="config-row-title config-row-title-shorter"
                  >Administrator</span
                >
              </div>
              <div class="config-row clearfix flex" v-if="type == 0">
                <span class="config-row-title config-row-title-shorter"
                  >登陆密码</span
                >
                <div style="width: 400px">
                  <el-input
                    show-password
                    v-model="generateOrderParam.vpsLoginPassword"
                    placeholder="请输入vps 密码"
                  ></el-input>
                  <span style="color:#999999;font-size:12px">密码为大小写字母、数字、特殊符号的组合，至少包含三种，长度 8-20 位</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="positon: relative">
          <loading v-if="loading"></loading>
        </div>
        <div class="config-view-inner" style="margin-top: 40px">
          <div class="config-tier margin-bottom-0">
            <div class="config-tier-title default-transition-tree-fast">
              <span style="top: 50%"> 价格 </span>
            </div>

            <div class="config-tier-panel"  >
              <div class="config-row clearfix" style="margin-left: 40px" v-if="type == 0">
                <el-input-number
                  v-model="num"
                  @change="handleChange"
                  :min="1"
                  :max="1000"
                  label="描述文字"
                ></el-input-number>
              </div>
              <div class="config-row clearfix">
                <span class="config-row-title" style="margin-left: 38px;width:45px">价格：</span>
                <div class="config-row-content">
                  <span class="text-stress strong server-price" v-if="type == 0">
                    <span>￥</span>{{item.price * num}}
                  </span>
                  <span class="text-stress strong server-price" v-if="type == 1">
                    <span>￥</span>{{item.price * num}}
                  </span>
                  <span class="text-stress strong server-price" v-if="type == 2">
                    <span>￥</span>{{item.price}}
                  </span>
                  <span class="text-stress strong server-price" v-if="type == 3">
                    <span>￥</span>{{item.jdyPrice}}
                  </span>
                  <span class="text-stress strong server-price" v-if="type == 4">
                    <span>￥</span>{{item.jdyPrice}}
                  </span>
                </div>
              </div>

              <div class="config-row clearfix" v-if="type == 4">
                <span class="config-row-title" style="margin-left: 38px;width:45px">数量：</span>
                <div class="config-row-content">
                  <span class="text-stress strong server-prices" style="font-size:20px;color:#666">
                    {{ num}}<span> 台</span>
                  </span>
                </div>
              </div>
              <div class="config-row clearfix" v-if="type == 4">
                <span class="config-row-title" style="margin-left: 38px;width:45px">总价：</span>
                <div class="config-row-content">
                  <span class="text-stress strong server-price">
                    <span>￥</span>{{totalJdyPrice
                    }}
                  </span>
                </div>
              </div>

              <div class="config-row clearfix" v-if="type == 2">
                <span class="config-row-title" style="margin-left: 38px;width:45px">数量：</span>
                <div class="config-row-content">
                  <span class="text-stress strong server-prices" style="font-size:20px;color:#666">
                    {{ num}}<span> 台</span>
                  </span>
                </div>
              </div>
              <div class="config-row clearfix" v-if="type == 2">
                <span class="config-row-title" style="margin-left: 38px;width:45px">总价：</span>
                <div class="config-row-content">
                  <span class="text-stress strong server-price">
                    <span>￥</span>{{totalPrice
                    }}
                  </span>
                </div>
              </div>
              <div class="item-pay">
                <div class="pay-way">
                  <p>支付平台</p>
                  <div
                    style="
                      display: flex;
                      align-item: center;
                      justify-aligin: center;
                    "
                  >
                    <div
                      class="pay pay-ali"
                      :class="{ checked: payType == 1 }"
                      @click="isLogin(1)"
                      v-if="payFlag == true"
                    ></div>
                    <div
                      @click="isLogin(2)"
                      class="pay pay-wechat"
                      :class="{ checked: payType == 2 }"
                      v-if="payFlag == true"
                    ></div>
                    <div class="payBtn" @click="isLogin(0)">余额购买</div>
                  </div>
                </div>
                <div v-if="!user" style="margin-top: 20px; color: #ff6600">
                  请登录，实名认证后购买
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      :showSure="showSkip"
      title="您还未登录"
      btnType="3"
      :showModal="showSkip"
      sureText="确认"
      cancelText="取消"
      @submit="goLogin"
      @cancel="goCancel"
    >
      <template v-slot:body>
        <p style="font-size: 18px">{{ s }}秒后跳转到登录页面</p>
      </template>
    </modal>
    <modal
      title="实名认证"
      btnType="3"
      :showModal="showCertification"
      sureText="确认"
      cancelText="取消"
      @cancel="showCertification = false"
      @submit="getCertification"
    >
      <template v-slot:body>
        <div class="certificationInput">
          <span>您的姓名</span>
          <el-input
            v-model="mgyUserVerifyParam.realUserName"
            placeholder="您的姓名"
          ></el-input>
        </div>
        <div class="certificationInput">
          <span>您的身份证号</span>
          <el-input
            v-model="mgyUserVerifyParam.idNumber"
            placeholder="您的身份证号"
          ></el-input>
        </div>
      </template>
    </modal>

    <modal
      title="支付确认"
      btnType="3"
      :showModal="showPayModal"
      sureText="已支付"
      cancelText="未支付"
      @cancel="showPayModal = false"
      @submit="checkOrder"
    >
      <template v-slot:body>
        <p>您确认是否完成支付？</p>
      </template>
    </modal>
    <modal
      title="去支付"
      btnType="3"
      :showModal="showAlipay"
      sureText="确认"
      cancelText="取消"
      @cancel="showAlipay = false"
      @submit="sureAlipay"
    >
      <template v-slot:body>
        <p>您确认要支付吗？</p>
      </template>
    </modal>

    <scan-pay-code
      title="实名认证"
      scan="支付宝"
      v-if="showPay"
      @close="showPay = false"
      @submit="completeCetification"
      @cancel="showPay = false"
      :img="payImg"
    ></scan-pay-code>
    <!-- 微信支付 -->
    <weixin-pay-code
      title="微信支付"
      scan="微信支付"
      v-if="showWeixinPay"
      @close="showWeixinPay = false"
      @submit="sureWeixinPay"
      @cancel="showWeixinPay = false"
      :img="code_url"
    ></weixin-pay-code>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import WeixinPayCode from "./../components/weixinPayCode";
import QRCode from "qrcode";
// @ is an alias to /src
import ScanPayCode from "./../components/scanPayCode";
import Modal from "./../components/Modal.vue";
import Loading from "./../components/loading.vue";
import NavHeader from "./../components/nav-header.vue";
export default {
  name: "host",
  components: { NavHeader, Loading, Modal, ScanPayCode, WeixinPayCode },
  data() {
    return {
      payFlag: true,
      showAlipay: false,
      showSkip: false,
      setTime: null,
      s: 5,
      mgyUserVerifyParam: {
        realUserName: "",
        idNumber: "",
      },
      payImg: "",
      //微信支付二维码地址
      code_url: "",
      //显示微信二维码支付
      showWeixinPay: false,
      //微信支付订单号
      wxOrderNo: "",
      showPay: false,
      payType: 1,
      showPayModal: false,
      loading: false,
      mirrorIndex: 0,
      monthIndex: 0,
      mgyProductPageParam: {
        pageIndex: 1,
        pageSize: 100,
      },
      item: {},
      id: 1,
      showCertification: false,
      productList: [],
      productSkuList: [],
      value: "",
      mirrorList: [],
      mirror: "",
      mirrorId: "",
      num: 1,
      generateOrderParam: {
        productId: 0,
        skuId: 0,
        mirrorId: 0,
        payType: 1,
        count: 1,
        vpsLoginPassword: "",
      },
      certifyId: "",
      outTradeNo: "",
      alipayInfo: {},
      type: 0,
      //续费相关
      mgyRenewalParam: {
        payType: 0,
        vpsId: 0,
        skuId: 0,
        versionType: null,
      },
      vpsId: "",
      versionType: null
    };
  },
  created() {
    //如果登陆了
    let token = window.localStorage.getItem("token");
    if (token) {
      this.updateUser();
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
      this.vpsId = this.$route.query.vpsId;
      this.versionType = this.$route.query.versionType;
      if(this.type == 2){
         this.num =  parseInt(this.$route.query.num)
      }
      if(this.type == 4){
         this.num =  parseInt(this.$route.query.num)
      }

    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getProductList();
    } else {
      this.$message.error("系统有误");
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    totalPrice() {
      return this.item.price * this.num.toFixed(2);
    },
    totalJdyPrice() {
      return this.item.jdyPrice * this.num.toFixed(2);
    },
    totalNum() {
      return this.user.userBalance.toFixed(2);
    },
  },
  filters: {
    handleNum(val) {
      if (val) {
        return val.toFixed(2);
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions(["updateUser"]),
    goLogin(){
      this.$router.push("/login");
    },
    certification() {
      this.$http
        .post("/mgyUser/verifyUser", this.mgyUserVerifyParam)
        .then((res) => {
          console.log(res.data);
          if (res.data.code == 200) {
            const certify = JSON.parse(res.data.data);
            if (certify.Code !== 200) {
              this.$message.error(certify.Message);
            } else {
              this.certifyId = certify.Data.certifyId;
              this.getRealCode(certify.Data.certifyUrl);
            }
          } else {
            this.$message.error(res.data.data);
          }
        });
    },
    getProductList() {
      var productId = this.id;
      this.$http.get(`/mgyProduct/info/`+productId).then((res) => {
        this.productList = res.data.data;
        this.productSkuList = this.productList.productSkuList;
        this.item = this.productSkuList[0];
        this.value = this.productSkuList[0].showTime;
        this.mirrorList = this.productList.mirrorList;
        this.mirrorId = this.mirrorList[0].id;
        if(this.item == 206 || this.itme == 207){
          this.payFlag = false;
        } else {
          this.payFlag = true;
        }
      })
    },
    goPay(index) {
      if (this.type == 0) {
        var reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,20}$/;
        if(!this.generateOrderParam.vpsLoginPassword.match(reg)){
          return this.$message.error("密码为大小写字母、数字、特殊符号的组合，至少包含三种，长度 8-20 位");
        }
        if (this.mirrorId == "") {
          return this.$message.error("请选择镜像");
        }
        this.loading = true;
        this.generateOrderParam.count = this.num;
        this.generateOrderParam.productId = this.id;
        this.generateOrderParam.skuId = this.item.id;
        this.generateOrderParam.payType = index;
        this.generateOrderParam.mirrorId = this.mirrorId;
        console.log(this.generateOrderParam);
        this.$http
          .post("/mgyOrder/generateOrder", this.generateOrderParam)
          .then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              console.log(res.data);
              if (res.data.data == false) {
                this.$message.error("账户余额不足");
              } else if (res.data.data == "当前无网络资源可用") {
                this.$message.error("当前无网络资源可用");
              } else if (res.data.data == 5) {
                this.$message.error("仅限新用户下单")
              } else {
                this.updateUser();
                this.$message.success("支付成功");
                this.$router.push({ name: "myProduct" });
              }
            }
          }).catch((err)=>{

          });
      } else if (this.type == 1) {
        //续费
        this.loading = true;
        this.mgyRenewalParam.skuId = this.item.id;
        this.mgyRenewalParam.payType = index;
        this.mgyRenewalParam.vpsId = this.vpsId;
        this.mgyRenewalParam.versionType = this.versionType;
        console.log(this.mgyRenewalParam);
        this.$http.post("/mgyVps/renewal", this.mgyRenewalParam).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            if (res.data.data == 0) {
              this.updateUser();
              this.$message.success("续费成功");
              this.$router.push("/myProduct");
            } else if (res.data.data == 2) {
              this.$message.error("账户余额不足");
            } else if (res.data.data == 5) {
              this.$message.error("仅限新用户下单");
            }else if (res.data.data == 6) {
              this.$message.error("机器过期超过72小时,如需使用请开通新机器");
            }
          }
        }).catch((err)=>{

        });
      } else if (this.type == 3) {
        //高级版续费
        this.loading = true;
        this.mgyRenewalParam.skuId = this.item.id;
        this.mgyRenewalParam.payType = index;
        this.mgyRenewalParam.vpsId = this.vpsId;
        this.mgyRenewalParam.versionType = this.versionType;
        console.log(this.mgyRenewalParam);
        this.$http.post("/mgyVps/renewal", this.mgyRenewalParam).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            if (res.data.data == 0) {
              this.updateUser();
              this.$message.success("续费成功");
              this.$router.push("/myProduct");
            } else if (res.data.data == 2) {
              this.$message.error("账户余额不足");
            } else if (res.data.data == 5) {
              this.$message.error("仅限新用户下单");
            }else if (res.data.data == 6) {
              this.$message.error("机器过期超过72小时,如需使用请开通新机器");
            }
          }
        }).catch((err)=>{

        });
      } else if (this.type == 2){
        //批量续费
        this.loading = true;
        this.mgyRenewalParam.skuId = this.item.id;
        this.mgyRenewalParam.payType = 0;
        this.mgyRenewalParam.vpsId = this.vpsId;
        this.mgyRenewalParam.versionType = this.versionType;
        console.log(this.mgyRenewalParam);
        console.log("versionType--->"+this.versionType);
        this.$http.post("/mgyVps/batchRenewal", this.mgyRenewalParam).then((res) => {
          this.loading = false;
          console.log(res);
          if (res.data.code == 200) {
            console.log(res.data.data);
            if (res.data.data == 0) {
              this.updateUser();
              this.$message.success("续费成功");
              this.$router.push("/myProduct");
            } else if (res.data.data == 2) {
              this.$message.error("账户余额不足");
            } else if (res.data.data == 5) {
              this.$message.error("仅限新用户下单");
            }
          }else{
            this.$message.error("请选择同一产品的vps！");
          }
        }).catch((err)=>{

        });
      }
    },
    newAlipay() {
      if (this.type == 0) {
        var reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,20}$/;
        if(!this.generateOrderParam.vpsLoginPassword.match(reg)){
          return this.$message.error("密码为大小写字母、数字、特殊符号的组合，至少包含三种，长度 8-20 位");
        }
        if (this.mirrorId == "") {
          return this.$message.error("请选择镜像");
        }
        this.loading = true;
        this.generateOrderParam.count = this.num;
        this.generateOrderParam.productId = this.id;
        this.generateOrderParam.skuId = this.item.id;
        this.generateOrderParam.payType = 1;
        this.generateOrderParam.mirrorId = this.mirrorId;
        this.$http
          .post("/mgyOrder/generateOrder", this.generateOrderParam)
          .then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              this.showAlipay = true;
              this.alipayInfo = res.data.data;
            } else {
              this.$message.error("系统错误");
            }
          }).catch((err)=>{

          });
      } else if (this.type == 1) {
        this.loading = true;
        this.mgyRenewalParam.skuId = this.item.id;
        this.mgyRenewalParam.payType = 1;
        this.mgyRenewalParam.vpsId = this.vpsId;
        console.log(this.mgyRenewalParam);
        this.$http.post("/mgyVps/renewal", this.mgyRenewalParam).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.showAlipay = true;
            this.alipayInfo = res.data.data;
          } else {
            this.$message.error("系统错误");
          }
        }).catch((err)=>{

        });
      } else if (this.type == 2){
        this.loading = true;
        this.mgyRenewalParam.skuId = this.item.id;
        this.mgyRenewalParam.payType = 1;
        this.mgyRenewalParam.vpsId = this.vpsId;
        console.log(this.mgyRenewalParam);
        this.$http.post("/mgyVps/batchRenewal", this.mgyRenewalParam).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.showAlipay = true;
            this.alipayInfo = res.data.data;
          } else {
            this.$message.error("请选择同一产品的vps！");
          }
        }).catch((err)=>{

        });
      }
    },
    alipay(e) {
      this.newAlipay(e);
    },
    //微信支付
    wexinpay() {
      this.payType = 2;
      if (this.type == 0) {
        var reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,20}$/;
        if(!this.generateOrderParam.vpsLoginPassword.match(reg)){
          return this.$message.error("密码为大小写字母、数字、特殊符号的组合，至少包含三种，长度 8-20 位");
        }
        if (this.mirrorId == "") {
          return this.$message.error("请选择镜像");
        }
        console.log('-----------------')
        this.loading = true;
        this.generateOrderParam.count = this.num;
        this.generateOrderParam.productId = this.id;
        this.generateOrderParam.skuId = this.item.id;
        this.generateOrderParam.payType = 2;
        this.generateOrderParam.mirrorId = this.mirrorId;
        this.$http
          .post("/mgyOrder/generateOrder", this.generateOrderParam)
          .then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              console.log(res);
              this.wxOrderNo = res.data.data.orderNo;
              this.getCode(res.data.data.code_url);
            } else {
              this.$message.error("系统错误");
            }
          }).catch((err)=>{

          });
      } else if (this.type == 1) {
        this.loading = true;
        this.mgyRenewalParam.skuId = this.item.id;
        this.mgyRenewalParam.payType = 2;
        this.mgyRenewalParam.vpsId = this.vpsId;
        console.log(this.mgyRenewalParam);
        this.$http.post("/mgyVps/renewal", this.mgyRenewalParam).then((res) => {
          this.loading = false;
          console.log(res);
          if (res.data.code == 200) {
            this.wxOrderNo = res.data.data.orderNo;
            this.getCode(res.data.data.code_url);
          } else {
            this.$message.error("系统错误");
          }
        }).catch((err)=>{

        });
      } else if (this.type == 2){
        this.loading = true;
        this.mgyRenewalParam.skuId = this.item.id;
        this.mgyRenewalParam.payType = 2;
        this.mgyRenewalParam.vpsId = this.vpsId;
        console.log(this.mgyRenewalParam);
        this.$http.post("/mgyVps/batchRenewal", this.mgyRenewalParam).then((res) => {
         this.loading = false;
          console.log(res);
          if (res.data.code == 200) {
            this.wxOrderNo = res.data.data.orderNo;
            this.getCode(res.data.data.code_url);
          } else {
            this.$message.error("系统错误");
          }
        }).catch((err)=>{

        });
      }
    },
    getOrder() {
    },
    change(e, index) {
      if(e.id == 206 || e.id == 207){
        this.payFlag = false;
      }else{
        this.payFlag = true;
      }
      this.monthIndex = index;
      this.item = e;
      console.log(this.payFlag);
    },
    chooseMirror(e, index) {
      console.log(index);
      this.mirrorIndex = index;
      this.mirrorId = e.id;
    },
    handleChange(value) {
      console.log(value);
    },
    goOrderList() {
      this.showPayModal = false;
    },
    // 关闭支付宝确认弹框
    closePayModal() {
      this.showPay = false;
      this.showPayModal = true;
      clearInterval(this.T);
    },

    getCertification() {
      this.certification();
      this.showCertification = false;
    },
    getCode(e) {
      QRCode.toDataURL(e).then((url) => {
       console.log(this.showWeixinPay);
          this.showWeixinPay = true;
          this.code_url = url;
      });
    },
    getRealCode(e){
       QRCode.toDataURL(e).then((url) => {
        this.showPay = true;
          this.payImg = url;
      });
    },
    completeCetification() {
      this.showPay = false;
      console.log(this.certifyId);
      this.$http
        .get(`/mgyUser/verifyUserCallBack?certifyId=${this.certifyId}`)
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            if (res.data.data) {
              this.$message.success("您已完成实名认证，去购买商品吧");
              this.updateUser();
            } else {
              this.$message.error("实名认证失败");
            }
          } else {
            this.$message.error("实名认证失败");
          }
        });
    },
    isLogin(e) {
      this.payType = e;
      if (this.type == 0) {
        if (this.generateOrderParam.vpsLoginPassword == "") {
          return this.$message.error("请先设置vps登陆密码");
        }
      }

      if (!this.user) {
        this.showSkip = true;
        this.setTime = setInterval(() => {
          this.countDown();
        }, 1000);
      } else {
        this.isRea1name(e);
      }
    },
    isRea1name(index) {
      if (this.user.isReal) {
        this.pay(index);
      } else {
        return (this.showCertification = true);
      }
    },
    pay(index) {
      if (index == 0) {
        this.open(index);
      } else if (index == 1) {
        this.alipay(index);
      } else if (index == 2) {
        this.wexinpay(index);
      }
    },
    open(e) {
      this.$confirm(`您当前余额为${this.totalNum}`, "您确定使用余额购买吗", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(() => {
          this.goPay(e);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "您已取消",
          });
        });
    },
    countDown() {
      if (this.s > 0) {
        this.s -= 1;
      } else if (this.s == 0) {
        clearInterval(this.setTime);
        return this.$router.push({ name: "login" });
      }
    },
    goCancel() {
      this.showSkip = false;
      clearInterval(this.setTime);
    },
    //阿里支付回调
    checkOrder() {
      this.showPayModal = false;
      this.$http
        .post(`/payBack/renewalAliPayCallBack/${this.outTradeNo}/${this.vpsId}/`)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("支付成功");
              this.$router.push("/myProduct");
            this.updateUser();
          } else {
            this.$message.error(res.data.data);
          }
        });
    },
    sureAlipay() {
      this.showAlipay = false;
      this.outTradeNo = this.alipayInfo.outTradeNo;
      let routeData = this.$router.resolve({
        name: "alipay",
        query: { content: this.alipayInfo.body },
      });
      window.open(routeData.href, "_blank");
      this.showPayModal = true;
    },
    sureWeixinPay() {
      if (this.type == 0) {
        this.showWeixinPay = false;
        console.log(this.wxOrderNo);
        this.$http
          .get(`/payBack/wxPaySearchOrder/${this.wxOrderNo}`)
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.message);
              this.$router.push({ name: "myProduct" });
              this.updateUser();
            } else if (res.data.code == 500) {
              this.$message.error(res.data.data);
            } else {
              console.log("bad");
            }
          });
      }  if (this.type == 1 || this.type == 2) {
        this.showWeixinPay = false;
        console.log(this.wxOrderNo);
        this.$http
          .get(`/payBack/wxPaySearchOrder/${this.wxOrderNo}`)
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.message);
              this.$router.push({ name: "myProduct" });
              this.updateUser();
            } else if (res.data.code == 500) {
              this.$message.error(res.data.data);
            } else {
              console.log("bad");
            }
          });
      }
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/buy.css";
@import "../assets/css/hosting.css";
@import "../assets/css/product.css";
@import "../assets/css/introduce.css";
@import "../assets/css/host.css";
.lastNum {
  border-right: 1px solid #ccc;
}
.certificationInput {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  span {
    width: 200px;
  }
}
.item-pay {
  padding: 26px 40px 72px;
  background-color: #ffffff;
  color: #333333;
  h3 {
    font-size: 20px;
    font-weight: 200;
    color: #333333;
    padding-bottom: 24px;
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 26px;
  }
  .pay-way {
    font-size: 18px;
    .pay {
      display: inline-block;
      width: 188px;
      height: 64px;
      border: 1px solid #d7d7d7;
      cursor: pointer;
      &:last-child {
        margin-left: 20px;
      }
      &.checked {
        border: 1px solid #ff6700;
      }
    }
    .pay-ali {
      background: url("../assets/images/pay/icon-ali.png") no-repeat center;
      background-size: 103px 38px;
      margin-right: 20px;
      margin-top: 20px;
    }
    .pay-wechat {
      background: url("../assets/images/pay/icon-wechat.png") no-repeat center;
      background-size: 103px 38px;
      margin-right: 20px;
      margin-top: 20px;
    }
  }
}
.payBtn {
  margin-top: 20px !important;
  display: inline-block;
  line-height: 64px;
  background-color: #ff6600;
  text-align: center;
  color: white;
  width: 188px;
  cursor: pointer;
}
.mirror {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  .chooseMirror {
    background-color: white;
    color: #333333;
    width: 180px;
    text-align: center;
    line-height: 40px;
  }
}
.month {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  cursor: pointer;
  .chooseMonth {
    width: 80px;
    text-align: center;
    line-height: 40px;
    background-color: white;
    color: #333333;
  }
}
.chooseColor {
  background-color: #059fff !important;
  color: white !important;
}
</style>
