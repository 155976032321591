<template>
  <div>
    <nav-header></nav-header>
    <div class="info-banner">
      <div class="info-box">
        <div class="auto">
          <div class="info-nav">
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-tabs class="tabs-area" v-model="activeName" @tab-click="handleClick" >
        <el-tab-pane  name="first">
          <span slot="label" class="area-text">标准版</span>
        </el-tab-pane>
        <el-tab-pane name="second">
          <span slot="label" class="area-text">高级版</span>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="hostColor" style="margin-top:-10px">
      <div class="citySearch">
        <el-input v-model="provinceSearchValue" placeholder="请输入省份"/>
        <el-input v-model="citySearchValue" placeholder="请输入城市"/>
        <el-button type="success" @click="citySearchBtn()">搜 索</el-button>
      </div>
      <!-- <div class="trusteeship-info"></div> -->
      <!-- 推荐套餐 -->
      <div class="module recommend-package">
        <p class="module-title" style="height: 12px;">
          <span class="module-title-name"></span>
          <!-- <span class="group-span"></span> -->
        </p>
          <el-table
          :data="productList"
          stripe>
          <el-table-column
            prop="province"
            label="省份">
          </el-table-column>
          <el-table-column
            prop="city"
            label="城市">
          </el-table-column>
          <el-table-column
            prop="isp"
            label="运营商"
            width="180">
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态">
            <template slot-scope="{row}">
              <el-tag v-if="row.status=='yes'" type="success">正常</el-tag>
              <el-tag v-if="row.status=='no'" type="warning">维护</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavHeader from "../components/nav-header.vue";
export default {
  name: "host",
  components: { NavHeader },
  data() {
    return {
      mgyProductPageParam: {
        pageIndex: 1,
        pageSize: 100,
        keyword: "指定区域",
      },
      isShow: true,
      citySearchValue: '',
      provinceSearchValue: '',
      activeName: 'first',
      productList: [],
    };
  },
  created() {
    this.getProduct();
  },
  methods: {
    getProduct() {
      if (this.activeName === 'first') {
        this.$http
            .get("/mgyPptpServer/getPptpServerList2")
            .then((res) => {
              this.productList = res.data.data;
            });
      }
      if (this.activeName === 'second') {
        this.$http
            .get("/mgyPptpServer/getPptpServerList2New")
            .then((res) => {
              this.productList = res.data.data;
            });
      }
    },
    handleClick(tab, event) {
      this.getProduct();
    },
    citySearchBtn(){
      if(this.provinceSearchValue != null || this.citySearchValue != null){
        this.$http
          .get("/mgyPptpServer/getPptpServerList2?province="+this.provinceSearchValue+"&city="+this.citySearchValue)
          .then((res) => {
            this.productList = res.data.data;
          });
      }
      
    }
  },
};
</script>
<style>
@import "../assets/css/introduce.css";
@import "../assets/css/host.css";
.tabs-area {
  padding-left: 700px;
  height: 50px; 
  width: 200px;
  text-align: center; 
}
.tabs-area .el-tabs__header {
  height: 50px; 
  width: 200px;
}
.area-text {
  font-size: 24px;
}
.tabs-area .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav{
  height: 50px; 
}
.home{
  background: #ffffff;
}
.hostColor {
  background: #ffffff !important;
}
.module {
  width: 200px;
}
.recommend-package > ul > li > .line {
  display: none;
}
.recommend-package > ul > li > .name {
  width: 310px;
}
.productA{
  color: blue;
}
.productB{
  color: #000000;
}
.my-label {
  font-size: 25px;
  font-weight: bold;
}
.citySearch{
  width: 63%;
  margin: 0 auto;
  display: flex;
}
.citySearch .el-input{
  width: 200px;
  margin-top: 30px;
  margin-right: 20px;
}
.citySearch .el-button{
  margin-left: 20px;
  width: 120px;
  margin-top: 30px;
}
</style>
