<template>
  <div>
    <el-dialog title="vps列表" :visible.sync="dialogTableVisible" center>

      <el-descriptions direction="vertical" margin-right:10px border>
        <el-descriptions-item align="center">
          <el-tag size="medium" v-for="item in currentVpsData">{{ item }}</el-tag>
        </el-descriptions-item>
      </el-descriptions>

    </el-dialog>
    <el-card>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>交易明细</el-breadcrumb-item>
    </el-breadcrumb>
    <div>

    <div class="filter-container">
      <el-input v-model="mgyChargeRecordPageParam.orderNo" placeholder="订单号" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter" />&nbsp;
      <el-input v-model="mgyChargeRecordPageParam.vpsName" placeholder="机器名称" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter" />&nbsp;
      <el-select v-model="mgyChargeRecordPageParam.type" placeholder="请选择类型" style="width: 140px" class="filter-item">
        <el-option
          v-for="item in sortOptions"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>
      <el-button class="filter-item" type="primary" style="margin-left:7px" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
    </div>
      <el-table :data="list" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="订单号" prop="orderNo"></el-table-column>
        <el-table-column label="交易金额" prop="amount"></el-table-column>
        <el-table-column label="交易后余额" prop="afterBalance"></el-table-column>
        <el-table-column label="支付类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 0">余额充值</span>
            <span v-if="scope.row.type == 1">余额支付</span>
            <span v-if="scope.row.type == 2">充值赠送</span>
            <span v-if="scope.row.type == 3">线上支付</span>
            <span v-if="scope.row.type == 4">后台充值</span>
            <span v-if="scope.row.type == 5">自动续费</span>
          </template>
        </el-table-column>
        <el-table-column label="交易说明" prop="remark"></el-table-column>
        <el-table-column label="vps列表" prop="vpsNameList">
          <template slot-scope="{row}">
            <span>{{ row.vpsList }}</span>
          </template>
<!--          <template slot-scope="scope">-->
<!--            <el-button type="text" @click="showVps(scope.row.vpsList)">查看VPS</el-button>-->
<!--          </template>-->
        </el-table-column>
        <el-table-column label="交易时间" prop="createTime"></el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.mgyChargeRecordPageParam.pageIndex"
          :page-sizes="[5, 10, 20, 30]"
          :page-size="this.mgyChargeRecordPageParam.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    </el-card>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "product",
  components: {},
  data() {
    return {
      mgyChargeRecordPageParam: {
        pageIndex: 1,
        pageSize: 5,
        orderNo: null,
        vpsName: null,
        type: null
      },
      list: [],
      total: 0,
      currentVpsData: [],
      dialogTableVisible: false,
      sortOptions: [
        { label: "余额充值", key: 0 },
        { label: "余额支付", key: 1 },
        { label: "充值赠送", key: 2 },
        { label: "线上支付", key: 3 },
        { label: "后台充值", key: 4 },
        { label: "自动续费", key: 5 },
        { label: "全部", key: null },
      ]
    };
  },
  mounted() {
    this.getTransationList();
  },
  methods: {
    handleFilter() {
      this.mgyChargeRecordPageParam.pageIndex = 1;
      this.getTransationList();
    },
    getTransationList() {
      this.$http
        .post(
          "/mgyTransactionDetail/getPageListByUser",
          this.mgyChargeRecordPageParam
        )
        .then((res) => {
          console.log(res);
          this.list = res.data.data.records;
          this.total = res.data.data.total;
        });
    },
    handleSizeChange(val) {
      this.mgyChargeRecordPageParam.pageSize = val;
      this.getTransationList();
    },
    showVps(v) {
      console.log(v)
      this.dialogTableVisible=true
      this.currentVpsData = v;
    },
    handleCurrentChange(val) {
      this.mgyChargeRecordPageParam.pageIndex = val;
      this.getTransationList();
    },
  },
};
</script>
<style></style>
