<template>
  <div>
    <el-dialog title="vps列表" :visible.sync="dialogTableVisible" center>

      <el-descriptions direction="vertical" margin-right:10px border>
        <el-descriptions-item align="center">
          <el-tag size="medium" v-for="item in currentVpsData">{{ item }}</el-tag>
        </el-descriptions-item>
      </el-descriptions>

    </el-dialog>

    <el-card>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>我的订单</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-table :data="orderlist" border stripe>
          <el-table-column type="index"></el-table-column>
          <el-table-column label="订单号" prop="uuid"></el-table-column>
          <el-table-column label="支付" prop="">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" v-if="scope.row.state == 0"
              >未支付
              </el-button
              >
              <el-button size="mini" type="primary" v-if="scope.row.state == 1"
              >已支付
              </el-button
              >
            </template>
          </el-table-column>
          <el-table-column label="支付方式" prop="">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" v-if="scope.row.payType == 0"
              >余额支付
              </el-button
              >
              <el-button size="mini" type="primary" v-if="scope.row.payType == 1"
              >支付宝支付
              </el-button
              >
              <el-button size="mini" type="primary" v-if="scope.row.payType == 2"
              >微信支付
              </el-button
              >
            </template>
          </el-table-column>
          <el-table-column label="订单类型" prop="">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" v-if="scope.row.orderType == 1"
              >新开通
              </el-button
              >
              <el-button size="mini" type="primary" v-if="scope.row.orderType == 2"
              >续费
              </el-button
              >
            </template>
          </el-table-column>
          <el-table-column label="vps硬盘" prop="vpsSsd"></el-table-column>
          <el-table-column label="cpu" prop="vpsCpu"></el-table-column>
          <el-table-column label="vps内存" prop="vpsMemory"></el-table-column>
          <el-table-column label="vps带宽" prop="vpsBandwidth"></el-table-column>
          <el-table-column label="单价" prop="unitPrice"></el-table-column>
          <el-table-column label="数量" prop="count"></el-table-column>
          <el-table-column label="总金额" prop="amount"></el-table-column>
          <el-table-column label="购买时长" prop="showTime"></el-table-column>
          <el-table-column label="下单时间" prop="beginTime"></el-table-column>
          <el-table-column label="订单价格" prop="amount"></el-table-column>

          <el-table-column label="vps列表" prop="vpsNameList">
            <template slot-scope="scope">
              <el-button type="text" @click="showVps(scope.row.vpsList)">查看VPS</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <!-- <span class="demonstration">完整功能</span> -->
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="this.mgyOrderPageParam.pageIndex"
              :page-sizes="[5, 10, 20, 30]"
              :page-size="this.mgyOrderPageParam.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>


<script>
// @ is an alias to /src
export default {
  name: "product",
  components: {},
  data() {
    return {
      mgyOrderPageParam: {
        pageIndex: 1,
        pageSize: 5,
      },
      form: {
        vpsName: null,
      },
      vpsName: null,
      orderlist: [],
      dialogTableVisible: false,
      dialogStatus: '',
      total: 0,
      currentVpsData: []
    };
  },
  created() {
    this.getOrder();
  },
  methods: {
    getOrder() {
      this.$http
        .post("/mgyOrder/getPageListByUser", this.mgyOrderPageParam)
        .then((res) => {
          console.log(res)
          this.orderlist = res.data.data.records;
          this.total = res.data.data.total;
        });
    },
    handleSizeChange(val) {
      this.mgyOrderPageParam.pageSize = val;
      this.getOrder();
    },
    handleCurrentChange(val) {
      this.mgyOrderPageParam.pageIndex = val;
      this.getOrder();
    },
    showVps(v) {
      console.log(v)
      this.dialogTableVisible=true
      this.currentVpsData = v;
    },
  },
};
</script>
<style></style>
