<template>
  <div class="">
    <!-- banner begin -->
    <nav-header></nav-header>
    <!--banner-->
    <!--banner-->
<div class="security-banner">
  <div class="container">
    <div class="header-info">
      <h1>关于我们</h1>
      <p>质量为本、客户为根、勇于拼搏、务实创新</p>
    </div>
  </div>
</div>
<div class="cloumn-blank"></div>
<!--栏目-->
<div class="security-cloumn">
  <ul>
    <li class="active" data-obj="scoll2">公司简介</li>
  </ul>
</div>

<!--5天无理由退款-->
<div class="security-refund scoll3" id="part3">
  <div class="container">
    <h1 class="security-title"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;江苏蘑菇云信息技术有限公司，专注为个人开发者用户、中小型、大型企业用户提供一站式核心网络云端部署服务，促使用户云端部署化简为零，轻松快捷运用云计算。蘑菇云是国内为数不多具有ICP/IP-VPN资质的专业云计算服务商。
       作为国内领先的云计算服务商，蘑菇云网络有着完善的行业解决方案和卓越的云计算技术。自主研发的纯SSD架构云服务器，以50,000IOPS随机读写速度、800Mb/s吞吐量的高性能数值刷新行业记录。其整合资源、细化资源到落地资源的服务举措，旨在打造差异化的开放式闭环生态系统，帮助用户快速构建稳定、安全的云计算环境。且云计算强大的计算能力和弹性扩展优势有效降低用户开发运维难度和整体IT成本，让用户能更专注于核心业务的创新，实现自身更多价值。
       自成立以来，蘑菇云始终本着质量为本、客户为根、勇于拼搏、务实创新的理念，不断提升产品硬件性能、创新底层虚拟化技术、革新用户服务体验，助力更多的合作伙伴、中小企业、开发者能够受益于云计算带来的便利和价值，从而共同打造全面开放的云端生态系统，促进云计算产业健康良性发展。未来，蘑菇云在提供专业云计算服务的同时，还将聚集云计算行业产业链各环节优势资源，继续发力云计算市场充分发挥聚合能力，实现多方共赢。</span></h1>

  </div>
</div>
  </div>
</template>
<script type="text/javascript" src="../assets/js/jquery_nicescroll.js"  ></script>
<script type="text/javascript" src="../assets/js/jquery.min.js"  ></script>
<script>
import NavHeader from "./../components/nav-header.vue";
export default {
  name: "security",
  components: { NavHeader },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style scoped src="../assets/css/about.css" >

</style>