<template>
  <div style="background: #fff">
    <nav-header></nav-header>

    <!--banner-->
    <div class="hosting-banner">
      <div class="header-info">
        <h1>云虚拟主机</h1>
        <p>
          稳定、安全、弹性、高性能的云端计算服务，实时满足您的多样性业务需求
        </p>
      </div>
    </div>
    <!--配置-->
    <div class="hosting-configuration">
      <div class="main-content auto clearfix p-relative">
        <div class="clearfix" style="height:200px">
          <div
            class="cl  config-choices float-left config-panel"
            style="height:200px"
            id="hostConfigsChoose"
          >
            <!-- 硬件信息-->
            <div class="config-tier">
              <div
                style="height:150px;line-height:150px"
                class="config-tier-title default-transition-tree-fast"
              >
                <span style="top:25%"> {{ product.productTitle }} </span>
              </div>
              <div class="show-configuration-box clearfix">
                <div class="configuration-item">
                  <span class="config-name">硬盘</span>
                  <span class="config-value" id="cpuModel">{{
                    product.productSsd
                  }}</span>
                </div>
                <div class="configuration-item">
                  <span class="config-name">CPU</span>
                  <span class="config-value" id="cpuModel">{{
                    product.productCpu
                  }}</span>
                </div>
                <div class="configuration-item">
                  <span class="config-name">内存</span>
                  <span class="config-value" id="cpuModel">{{
                    product.productMemory
                  }}</span>
                </div>
                <div class="configuration-item">
                  <span class="config-name">带宽</span>
                  <span class="config-value" id="cpuModel">{{
                    product.productBandwidth
                  }}</span>
                </div>
                <div class="configuration-item">
                  <span class="config-name">时长</span>
                  <span class="config-value" id="cpuModel">{{
                    item.showTime
                  }}</span>
                </div>
              </div>
            </div>

            <!-- 购买 -->
            <div class="config-view-inner">
              <div class="config-tier margin-bottom-0">
                <div class="config-tier-title default-transition-tree-fast">
                  <span style="top:50%;"> 价格 </span>
                </div>
                <div class="config-tier-panel">
                  <div class="config-row clearfix">
                    <span class="config-row-title">配置价格：</span>
                    <div class="config-row-content">
                      <span class="text-stress strong server-price">
                        <span class="">￥</span>{{ item.price
                        }}<span id="price"></span>
                      </span>
                      <span
                        id="originPriceOuter"
                        class="origin-price"
                        style="display: none"
                      >
                        <span class="">原价：</span>
                        <s>￥</s><s id="originPrice"></s>
                      </span>
                    </div>
                    <div
                      class="text-stress favorable-reminder gift-outer"
                      style="display: none"
                    ></div>
                  </div>
                  <input
                  @click="getOrder"
                    type="button"
                    class="server-buy-btn strong default-transition buyButton"
                    id="orderSubmit"
                    value="立即购买"
                  />
                  <p class="buy-no-login-tip no-login hide">
                    请先登录<span class="nologin-noauth-info"
                      >，本地域购买需要实名认证</span
                    >
                  </p>
                  <p class="buy-no-login-tip no-identity hide" data-auth="1">
                    本地域购买需要实名认证&nbsp;<a
                      target="/user/"
                      href="/user/auth/newAuth"
                      >&gt;&gt;请先认证&lt;&lt;</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--配置 end-->
  </div>
</template>

<script>
// @ is an alias to /src
import NavHeader from "./../components/nav-header.vue";
export default {
  name: "order",
  components: {
    NavHeader,
  },
  created() {
    if (this.$route.params.toItem) {
      this.item = JSON.parse(this.$route.params.toItem);
      console.log(this.item);
      this.getProductList();
      this.generateOrderParam.productId = this.item.productId;
      this.generateOrderParam.skuId = this.item.id;
    } else {
    }
  },
  data() {
    return {
      orderIndex: 0,
      itme: {},
      product: [],
      generateOrderParam: {
        productId: 0,
        skuId: 0,
        mirrorId: 0,
        payType: 1,
        count: 1,
      },
    };
  },
  methods: {
    getProductList() {
      this.$http.get(`/mgyProduct/info/${this.item.productId}`).then((res) => {
        console.log(res);
        this.product = res.data.data;
        this.generateOrderParam.mirrorId = res.data.data.mirrorList[0].id;
      });
    },
    getOrder() {
      this.$http.post("/mgyOrder/generateOrder", this.generateOrderParam).then(res =>{
        console.log(res)
      });
    },
  },
};
</script>
<style>
@import "../assets/css/buy.css";
@import "../assets/css/hosting.css";
@import "../assets/css/product.css";
</style>
