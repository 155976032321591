<template>
  <div class="">
    <!-- banner begin -->
    <nav-header></nav-header>
    <!--banner-->
    <div class="help-banner">
      <dl>
        <dt></dt>
        <dd>
          <form action="/help/search" method="get">
            <input
              name="k"
              type="text"
              placeholder="请输入您要搜索的关键词..."
              value=""
            /><input type="submit" value="" />
          </form>
          <ul>
            <li><a href="/help/search?k=云服务器">云服务器</a></li>
            <li><a href="/help/search?k=域名注册">域名注册</a></li>
            <li><a href="/help/search?k=虚拟主机">虚拟主机</a></li>
            <li><a href="/help/search?k=服务器租用">服务器租用</a></li>
          </ul>
        </dd>
      </dl>
    </div>
    <div class="help-index">
      <div class="container">
        <ul class="help-cloumn">
          <div style="clear: both">
            <li class="issue-type-item margin-left-0">
              <h4
                style="
                  background: url() no-repeat 6px 6px;
                  background-size: 30px 30px;
                "
              >
                注册与购买
              </h4>
              <p><a href="/help/class/5">账户问题</a></p>
              <p><a href="/help/class/6">选购指南</a></p>
              <p><a href="/help/class/7">付款结算</a></p>
              <p><a href="/help/class/8">合同及发票</a></p>
            </li>
            <li class="issue-type-item">
              <h4
                style="
                  background: url() no-repeat 6px 6px;
                  background-size: 30px 30px;
                "
              >
                备案问题
              </h4>
              <p><a href="/help/class/15">备案指南</a></p>
              <p><a href="/help/class/16">管局备案要求</a></p>
              <p><a href="/help/class/17">前置审批说明</a></p>
              <p><a href="/help/class/21">法律法规</a></p>
            </li>
            <li class="issue-type-item">
              <h4
                style="
                  background: url() no-repeat 6px 6px;
                  background-size: 30px 30px;
                "
              >
                云服务器问题
              </h4>
              <p><a href="/help/class/10">控制台使用</a></p>
              <p><a href="/help/class/11">基础类问题</a></p>
              <p><a href="/help/class/12">应用类问题</a></p>
              <p><a href="/help/class/13">网络类问题</a></p>
              <p><a href="/help/class/14">安全类问题</a></p>
            </li>
            <li class="issue-type-item">
              <h4
                style="
                  background: url() no-repeat 6px 6px;
                  background-size: 30px 30px;
                "
              >
                使用规则
              </h4>
              <p><a href="/help/class/23">常见问题</a></p>
              <p><a href="/help/class/24">规则说明</a></p>
              <p><a href="/help/class/30">总则</a></p>
              <p><a href="/help/class/31">信息安全处罚规则</a></p>
              <p><a href="/help/class/32">法律法规</a></p>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import NavHeader from "./../components/nav-header.vue";
export default {
  name: "banner",
  components: { NavHeader },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style scoped src="../assets/css/help.css" >

</style>