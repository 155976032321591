<template>
  <div>
    <el-card>
      <span style="margin-right: 20px">我的产品</span>
      <el-button size="mini" type="primary" @click="goProduct">新增vps</el-button>
      <el-tabs class="tabs-my" v-model="activeName" @tab-click="handleClick" >
        <el-tab-pane name="first">
          <span slot="label" class="area-text">标准版</span>
          <div style="margin-top: 15px">
            <el-form :inline="true" :model="mgyVpsPageParam" class="demo-form-inline">
              <el-form-item>
                <el-input v-model="mgyVpsPageParam.vpsName" @keyup.enter.native="getOrder" placeholder="vps名称"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input @keyup.enter.native="getOrder" v-model="mgyVpsPageParam.port" placeholder="端口号"></el-input>
              </el-form-item>
              <!--         <el-form-item>
                         <el-input @keyup.enter.native="getOrder" v-model="mgyVpsPageParam.pptpArea" placeholder="拨号区域"></el-input>
                       </el-form-item>-->
              <el-form-item>
                <el-button type="primary" @click="getOrder">查询</el-button>
                <el-button type="primary" @click="exportExcel">导出</el-button>
              </el-form-item>
            </el-form>
          </div>
          <el-table
              ref="multipleTable"
              :key="tableKey"
              :data="records"
              fit
              highlight-current-row
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              id="table1"
              stripe
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="vps名称" width="100">
              <template v-slot="scope">
                <div tabindex="1" @blur="remarkVps(scope.row)" @focus="focusVpsRemark()">
                  {{ scope.row['vpsName'] }} <br/>
                  <input
                      v-model="scope.row.remark"
                      @blur="remarkVps(scope.row)"
                      @focus="focusVpsRemark()"
                      @keyup.enter="onSubmit(scope.row)"
                      :class="vpsRemarkFlag === true ? 'remarkA' : ''"
                      placeholder="vps备注"
                      style="font-size: 14px;border-radius: 5px;width: 130px;z-index: 9999;"/>
                  <img
                      class="remark-icon"
                      @click="checkRemark(scope.row)"
                      v-show="vpsRemarkFlag"
                      src="https://mogu-pro.oss-cn-shanghai.aliyuncs.com/opr/1.png"
                      alt="备注"/>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="远程地址" width="178" align="center">
              <template v-slot="scope">
                {{ scope.row['vpsRemoteAddress'] }}
              </template>
            </el-table-column>
            <el-table-column label="用户名" width="100">
              <template v-slot="scope">
                {{ scope.row['vpsRemoteUserName'] }}
              </template>
            </el-table-column>
            <el-table-column label="密码" width="100">
              <template v-slot="scope">
                {{ scope.row['vpsRemotePassword'] }}
              </template>
            </el-table-column>
            <el-table-column width="120" label="宽带账号/密码">
              <template v-slot="scope">
                <template v-if="scope.row['pptpType'] === '混播'">
                  <span>{{ scope.row['bandAccount'] }}/{{scope.row['bandPassword'] }}</span>
                </template>
                <template v-if=" scope.row['pptpType'] === '指定区域' && scope.row.pptpArea == null">
                  <el-button @click="dialog(scope.row)" type="text" size="small">
                    设置拨号区域
                  </el-button>
                </template>
                <template v-if=" scope.row['pptpType'] === '指定区域' && scope.row.pptpArea != null">
              <span>
                {{ scope.row['bandAccount'] }}/<br/>{{ scope.row['bandPassword'] }}
              </span>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="产品类型" prop="productName" width="110"></el-table-column>
            <el-table-column label="当前拨号区域" width="170">
              <template v-slot="scope">
                <el-button
                    @click="dialog(scope.row)"
                    v-if="scope.row['pptpType'] === '指定区域'"
                    type="text"
                >
                  {{ scope.row.pptpArea }}
                </el-button>
                <el-button v-if="scope.row['pptpType'] === '混播'" type="text">
                  混播
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" width="140"></el-table-column>
            <el-table-column label="过期时间" prop="endTime" width="140"></el-table-column>
            <el-table-column label="配置" prop="mirrorName" width="120">
              <template v-slot="scope">
                <div>
                  {{ scope.row.mirrorName }}<br/>
                  {{ scope.row['vpsCpu'] }} 核 / {{ scope.row['vpsMemory'] }} G
                </div>
              </template>
            </el-table-column>

            <el-table-column label="自动续费" width="100">
              <template v-slot="scope">
                <template v-if="scope.row.autoRenew === 1">
                  <el-tag size="mini" type="primary"> 开 启</el-tag>
                </template>
                <template v-if="scope.row.autoRenew === 0">
                  <el-tag size="mini" type="info"> 关 闭</el-tag>
                </template>
              </template>
            </el-table-column>

            <el-table-column label="状态" width="100">
              <template v-slot="scope">
                <template v-if="scope.row.state !== 1 && scope.row.state !== 2">
                  <el-tag
                      size="mini"
                      type="info"
                      v-if="isCreation(scope.row['createTime'])"
                  ><i class="el-icon-loading"></i>创建中
                  </el-tag>
                  <el-tag
                      size="mini"
                      type="warning"
                      v-else-if="scope.row.operationState === -1"
                  >
                    <i class="el-icon-loading"></i>获取中
                  </el-tag
                  >
                  <el-tag
                      size="mini"
                      type="primary"
                      v-else-if="scope.row.operationState === 0"
                  >运行中
                  </el-tag
                  >
                  <el-tag
                      size="mini"
                      type="danger"
                      v-else-if="scope.row.operationState === 1"
                  >已关机
                  </el-tag
                  >
                  <el-tag
                      type="danger"
                      size="mini"
                      v-else-if="scope.row.operationState === 2"
                  ><i class="el-icon-loading"></i>开机中
                  </el-tag
                  >
                  <el-tag
                      type="danger"
                      size="mini"
                      v-else-if="scope.row.operationState === 5"
                  ><i class="el-icon-loading"></i>重装系统中
                  </el-tag
                  >
                  <el-tag
                      size="mini"
                      type="primary"
                      v-else-if="scope.row.operationState === 3"
                  ><i class="el-icon-loading"></i>关机中
                  </el-tag>
                  <el-tag
                      size="mini"
                      type="primary"
                      v-else-if="scope.row.operationState === 6"
                  ><i class="el-icon-loading"></i>重启中
                  </el-tag>
                  <el-tag
                      size="mini"
                      type="primary"
                      v-else-if="scope.row.operationState === 4"
                  >正在创建
                  </el-tag
                  >
                </template>
                <template v-if="scope.row.state === 1">
                  <el-tag size="mini" type="info">已过期</el-tag>
                </template>
                <template v-if="scope.row.state === 2">
                  <el-tag size="mini" type="info">已释放</el-tag>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="续费" width="80">
              <template v-slot="scope">
                <el-button size="mini" type="success" @click="goNewProduct(scope.row)">续费</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作">
              <template v-slot="scope">
                <el-dropdown>
                  <el-button style="width: 100px" type="primary">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        :disabled="
                   isDisabled(scope.row)
                  "
                    >
                      <div
                          @click="open(scope.row, 1)"
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                      >
                        启动
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item
                        :disabled="
                   isDisabled(scope.row)
                  "
                    >
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="close(scope.row, 0)"
                      >
                        关机
                      </div>
                    </el-dropdown-item
                    >
                    <el-dropdown-item
                        :disabled="
                   isDisabled(scope.row)
                  "
                    >
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="restart(scope.row, 2)"
                      >
                        重启
                      </div>
                    </el-dropdown-item
                    >
                    <el-dropdown-item
                        :disabled="
                   isDisabled(scope.row)
                  "
                    >
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="autoRenew(scope.row,4)"
                      >
                        自动续费
                      </div>
                    </el-dropdown-item
                    >
                    <el-dropdown-item
                        :disabled="
                  isDisabled(scope.row)
                  "
                    >
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="modify(scope.row)"
                      >
                        修改密码
                      </div>
                    </el-dropdown-item
                    >
                    <el-dropdown-item
                        :disabled="
                    isDisabled(scope.row)
                  "
                    >
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="dialog(scope.row)"
                          v-if="scope.row['pptpType'] === '指定区域'"
                      >
                        拨号区域
                      </div>
                    </el-dropdown-item
                    >
                    <el-dropdown-item
                        :disabled="isDisabled(scope.row)">
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="reload(scope.row, 3)"
                      >
                        重装系统
                      </div>
                    </el-dropdown-item
                    >
                    <el-dropdown-item>
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="forcedClose(scope.row)"
                      >
                        强制关机
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane name="second">
          <span slot="label" class="area-text">高级版</span>
          <div style="margin-top: 15px">
            <el-form :inline="true" :model="mgyVpsPageParam" class="demo-form-inline">
              <el-form-item>
                <el-input v-model="mgyVpsPageParam.vpsName" @keyup.enter.native="getOrder" placeholder="vps名称"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input @keyup.enter.native="getOrder" v-model="mgyVpsPageParam.port" placeholder="端口号"></el-input>
              </el-form-item>
              <!--         <el-form-item>
                         <el-input @keyup.enter.native="getOrder" v-model="mgyVpsPageParam.pptpArea" placeholder="拨号区域"></el-input>
                       </el-form-item>-->
              <el-form-item>
                <el-button type="primary" @click="getOrder">查询</el-button>
                <el-button type="primary" @click="exportExcel">导出</el-button>
              </el-form-item>
            </el-form>
          </div>
          <el-table
              ref="multipleTable"
              :key="tableKey"
              :data="records"
              fit
              highlight-current-row
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              id="table1"
              stripe
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="vps名称" width="100">
              <template v-slot="scope">
                <div tabindex="1" @blur="remarkVps(scope.row)" @focus="focusVpsRemark()">
                  {{ scope.row['vpsName'] }} <br/>
                  <input
                      v-model="scope.row.remark"
                      @blur="remarkVps(scope.row)"
                      @focus="focusVpsRemark()"
                      @keyup.enter="onSubmit(scope.row)"
                      :class="vpsRemarkFlag === true ? 'remarkA' : ''"
                      placeholder="vps备注"
                      style="font-size: 14px;border-radius: 5px;width: 130px;z-index: 9999;"/>
                  <img
                      class="remark-icon"
                      @click="checkRemark(scope.row)"
                      v-show="vpsRemarkFlag"
                      src="https://mogu-pro.oss-cn-shanghai.aliyuncs.com/opr/1.png"
                      alt="备注"/>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="远程地址" width="178" align="center">
              <template v-slot="scope">
                {{ scope.row['vpsRemoteAddress'] }}
              </template>
            </el-table-column>
            <el-table-column label="用户名" width="100">
              <template v-slot="scope">
                {{ scope.row['vpsRemoteUserName'] }}
              </template>
            </el-table-column>
            <el-table-column label="密码" width="100">
              <template v-slot="scope">
                {{ scope.row['vpsRemotePassword'] }}
              </template>
            </el-table-column>
            <el-table-column width="120" label="宽带账号/密码">
              <template v-slot="scope">
                <template v-if="scope.row['pptpType'] === '混播'">
                  <span>{{ scope.row['bandAccount'] }}/{{scope.row['bandPassword'] }}</span>
                </template>
                <template v-if=" scope.row['pptpType'] === '指定区域' && scope.row.pptpArea == null">
                  <el-button @click="dialog(scope.row)" type="text" size="small">
                    设置拨号区域
                  </el-button>
                </template>
                <template v-if=" scope.row['pptpType'] === '指定区域' && scope.row.pptpArea != null">
              <span>
                {{ scope.row['bandAccount'] }}/<br/>{{ scope.row['bandPassword'] }}
              </span>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="产品类型" prop="productName" width="110"></el-table-column>
            <el-table-column label="当前拨号区域" width="170">
              <template v-slot="scope">
                <el-button
                    @click="dialog(scope.row)"
                    v-if="scope.row['pptpType'] === '指定区域'"
                    type="text"
                >
                  {{ scope.row.pptpArea }}
                </el-button>
                <el-button v-if="scope.row['pptpType'] === '混播'" type="text">
                  混播
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" width="140"></el-table-column>
            <el-table-column label="过期时间" prop="endTime" width="140"></el-table-column>
            <el-table-column label="配置" prop="mirrorName" width="120">
              <template v-slot="scope">
                <div>
                  {{ scope.row.mirrorName }}<br/>
                  {{ scope.row['vpsCpu'] }} 核 / {{ scope.row['vpsMemory'] }} G
                </div>
              </template>
            </el-table-column>

            <el-table-column label="自动续费" width="100">
              <template v-slot="scope">
                <template v-if="scope.row.autoRenew === 1">
                  <el-tag size="mini" type="primary"> 开 启</el-tag>
                </template>
                <template v-if="scope.row.autoRenew === 0">
                  <el-tag size="mini" type="info"> 关 闭</el-tag>
                </template>
              </template>
            </el-table-column>

            <el-table-column label="状态" width="100">
              <template v-slot="scope">
                <template v-if="scope.row.state !== 1 && scope.row.state !== 2">
                  <el-tag
                      size="mini"
                      type="info"
                      v-if="isCreation(scope.row['createTime'])"
                  ><i class="el-icon-loading"></i>创建中
                  </el-tag>
                  <el-tag
                      size="mini"
                      type="warning"
                      v-else-if="scope.row.operationState === -1"
                  >
                    <i class="el-icon-loading"></i>获取中
                  </el-tag
                  >
                  <el-tag
                      size="mini"
                      type="primary"
                      v-else-if="scope.row.operationState === 0"
                  >运行中
                  </el-tag
                  >
                  <el-tag
                      size="mini"
                      type="danger"
                      v-else-if="scope.row.operationState === 1"
                  >已关机
                  </el-tag
                  >
                  <el-tag
                      type="danger"
                      size="mini"
                      v-else-if="scope.row.operationState === 2"
                  ><i class="el-icon-loading"></i>开机中
                  </el-tag
                  >
                  <el-tag
                      type="danger"
                      size="mini"
                      v-else-if="scope.row.operationState === 5"
                  ><i class="el-icon-loading"></i>重装系统中
                  </el-tag
                  >
                  <el-tag
                      size="mini"
                      type="primary"
                      v-else-if="scope.row.operationState === 3"
                  ><i class="el-icon-loading"></i>关机中
                  </el-tag>
                  <el-tag
                      size="mini"
                      type="primary"
                      v-else-if="scope.row.operationState === 6"
                  ><i class="el-icon-loading"></i>重启中
                  </el-tag>
                  <el-tag
                      size="mini"
                      type="primary"
                      v-else-if="scope.row.operationState === 4"
                  >正在创建
                  </el-tag
                  >
                </template>
                <template v-if="scope.row.state === 1">
                  <el-tag size="mini" type="info">已过期</el-tag>
                </template>
                <template v-if="scope.row.state === 2">
                  <el-tag size="mini" type="info">已释放</el-tag>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="续费" width="80">
              <template v-slot="scope">
                <el-button size="mini" type="success" @click="goNewProduct(scope.row)">续费</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作">
              <template v-slot="scope">
                <el-dropdown>
                  <el-button style="width: 100px" type="primary">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        :disabled="
                   isDisabled(scope.row)
                  "
                    >
                      <div
                          @click="open(scope.row, 1)"
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                      >
                        启动
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item
                        :disabled="
                   isDisabled(scope.row)
                  "
                    >
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="close(scope.row, 0)"
                      >
                        关机
                      </div>
                    </el-dropdown-item
                    >
                    <el-dropdown-item
                        :disabled="
                   isDisabled(scope.row)
                  "
                    >
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="restart(scope.row, 2)"
                      >
                        重启
                      </div>
                    </el-dropdown-item
                    >
                    <el-dropdown-item
                        :disabled="
                   isDisabled(scope.row)
                  "
                    >
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="autoRenew(scope.row,4)"
                      >
                        自动续费
                      </div>
                    </el-dropdown-item
                    >
                    <el-dropdown-item
                        :disabled="
                  isDisabled(scope.row)
                  "
                    >
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="modify(scope.row)"
                      >
                        修改密码
                      </div>
                    </el-dropdown-item
                    >
                    <el-dropdown-item
                        :disabled="
                    isDisabled(scope.row)
                  "
                    >
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="dialog(scope.row)"
                          v-if="scope.row['pptpType'] === '指定区域'"
                      >
                        拨号区域
                      </div>
                    </el-dropdown-item
                    >
                    <el-dropdown-item
                        :disabled="isDisabled(scope.row)">
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="reload(scope.row, 3)"
                      >
                        重装系统
                      </div>
                    </el-dropdown-item
                    >
                    <el-dropdown-item>
                      <div
                          style="
                      width: 60px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                          @click="forcedClose(scope.row)"
                      >
                        强制关机
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div style="margin-top: 20px">
        <el-button type="info" @click="openMultiple(0)">批量启动</el-button>
        <el-button type="danger" @click="closeMultiple(1)">批量关机</el-button>
        <el-button type="primary" @click="restartMultiple(2)">批量重启</el-button>
        <el-button type="warning" @click="restartMultiple(3)">批量重装系统</el-button>
        <el-button type="warning" @click="batchRenewal(4)">批量续费</el-button>
        <el-button type="warning" @click="batchSetPptpArea(5)">批量设置拨号区域</el-button>
        <el-button
            v-waves
            class="filter-item"
            style="margin-left: 20px" type="primary" @click="autoRenewBatch">批量自动续费</el-button>
      </div>
      <el-dialog
          :close-on-click-modal="false"
          title="提示"
          :visible.sync="centerDialogVisible"
          width="30%"
          center
      >
        <span>{{ title }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="onSure">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
          :close-on-click-modal="false"
          title="提示"
          :visible.sync="multipleDialogVisible"
          width="30%"
          center
      >
        <span>{{ title }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="multipleDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="multipleSure">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
          :close-on-click-modal="false"
          title="提示"
          :visible.sync="autoRenewDialogVisible"
          width="30%"
          center
      >
        <el-form
            ref="sysMenuForm"
            :model="autoRenewParam"
            label-position="right"
            label-width="60px"
            class="page-form"
            style="width: 80%;"
        >
          <el-form-item label="状态：">
            <el-radio-group v-model="autoRenewParam.autoRenew" border>
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="autoRenewDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="onSure">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
          :close-on-click-modal="false"
          title="选择镜像并填写vps密码"
          :visible.sync="centerDialog"
          width="30%"
          center
      >
        <el-input
            style="width: 370px"
            show-password
            v-model="reLoadItem.vpsPassword"
            placeholder="请输入vps 密码"
        ></el-input>
        <el-select
            style="width: 370px; margin-top: 20px"
            v-model="value"
            placeholder="请选择镜像"
        >
          <el-option
              v-for="item in recordsMirror"
              :key="item.id"
              :value="item.mirrorName"
          >
            <span
                @click="change(item)"
                style="float: right; color: #8492a6; font-size: 13px; width: 100%"
            >{{ item.mirrorName }}</span
            >
          </el-option>
        </el-select>
        <p style="color: red; position: absolute; margin-top: 8px">
          * 2核1G VPS禁止装 Windows10 系统
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialog = false">取 消</el-button>
          <el-button type="primary" @click="onSure">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
          :close-on-click-modal="false"
          title="请选择拨号区域"
          :visible.sync="showArea"
          width="32%"
          center
      >
        <div style="text-align: center">
          <el-cascader
              placeholder="拨号区域"
              :options="dialogList"
              @change="changeArea"
              ref="cascaderAddr"
              :props="props"
              filterable
          >
          </el-cascader>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showArea = false">取 消</el-button>
          <el-button type="primary" @click="onSureArea">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
          :close-on-click-modal="false"
          title="选择镜像并填写vps密码"
          :visible.sync="multpleMirrorDialog"
          width="30%"
          center
      >
        <el-input
            style="width: 370px"
            show-password
            v-model="password"
            placeholder="请输入vps 密码"
        ></el-input>
        <el-select
            style="width: 370px; margin-top: 20px"
            v-model="value"
            placeholder="请选择镜像"
        >
          <el-option
              v-for="item in recordsMirror"
              :key="item.id"
              :value="item.mirrorName"
          >
            <span
                @click="change(item)"
                style="float: right; color: #8492a6; font-size: 13px; width: 100%"
            >{{ item.mirrorName }}</span
            >
          </el-option>
        </el-select>
        <p style="color: red; position: absolute; margin-top: 8px">
          * 2核1G VPS禁止装 Windows10 系统
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="multpleMirrorDialog = false">取 消</el-button>
          <el-button type="primary" @click="multipleSure">确 定</el-button>
        </span>
      </el-dialog>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.mgyVpsPageParam.pageIndex"
            :page-sizes="[5, 10, 20, 30, 100,200]"
            :page-size="this.mgyVpsPageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
      <modal
          title="修改密码"
          btnType="3"
          :showModal="showCertification"
          sureText="确认"
          cancelText="取消"
          @cancel="showCertification = false"
          @submit="getCertification"
      >
        <template v-slot:body>
          <div class="certificationInput">
            <span>新的密码</span>
            <el-input
                show-password
                v-model="vpsUpdatePasswordParam.password"
                placeholder="新的密码"
            ></el-input>
          </div>
          <div
              style="
              color: #999999;
              font-size: 12px;
              display: block;
              margin-left: 135px;
              width: 270px;
              margin-top: 5px;
            "
          >
            密码为大小写字母、数字、特殊符号的组合，至少包含三种，长度 8-20 位
          </div>
        </template>
      </modal>
    </el-card>
  </div>
</template>
<style>

</style>
<script>
// @ is an alias to /src
import Modal from "./../components/Modal.vue";
import NavHeader from "./../components/nav-header.vue";
import mqtt from 'mqtt';
import * as XLSX from "xlsx";
import FileSaver from "file-saver";


export default {
  name: "order",
  components: {
    NavHeader,
    Modal,
  },
  data() {
    return {
      client: null,
      props: {
        value: "value",
        label: "label",
      },
      vpsRemarkFlag: false,
      showCertification: false,
      pptpServer: "",
      value: "",
      vpsRemark: "",
      mgyVpsPageParam: {
        pageIndex: 1,
        pageSize: 5,
        vpsName: '',
        port: null,
        versionType: null,
        pptpArea: ''
      },
      activeName: 'first',
      autoRenewParam: {
       vpsIds: [],
       autoRenew: 0
      },
      recordsMirror: [],
      centerDialogVisible: false,
      multipleDialogVisible: false,
      autoRenewDialogVisible: false,
      item: "",
      reLoadItem: {
        vpsId: "",
        mirrorId: "",
        vpsPassword: "",
      },
      tabIndex: "",
      title: "",
      centerDialog: false,
      mgyMirrorPageParam: {pageIndex: 1, pageSize: 100, vpsId: null},
      records: [],
      // listLoading: true,
      tableKey: 0,
      mirrorId: "",
      pptpAreaType: null,
      multipleSelection: [],
      multpleMirrorDialog: false,
      mgyBatchOperationParam: {
        vpsIds: [],
        mirrorIds: [],
        type: 0,
        vpsPassword: [],
      },
      mgyBatchPptpAreaParam: {
        vpsId: "",
        pptpArea: "",
        pptpHost: "",
      },
      total: 0,
      vpsUpdatePasswordParam: {
        password: "",
        vpsId: "",
      },
      password: "",
      mgyPptpServerPageParam: {
        pageIndex: 1,
        pageSize: 1000,
      },
      dialogList: [],
      showArea: false,
      mgyPptpAreaParam: {
        vpsId: "",
        pptpArea: "",
        pptpHost: "",
      },
      areaItem: "",
      mgyVpsRemarkParam: {
        vpsId: "",
        remark: "",
      },
      count: 0,
      currentVpsName: '',
      subscriptionList: []
    };
  },
  // destroyed() {
  //   // this.unSubscription(this.subscriptionList)
  //   this.client.end(function () {
  //     console.log("关闭连接")
  //   })
  // },
  created() {
    // this.initClient()
    this.getOrder()
    this.chooseArea();
  },
  methods: {
    isDisabled(item) {
      if (item.state === 0 && item.operationState === 0) return false
      return !(item.state === 0 && item.operationState === 1);

    },
    // 判断是否创建超过90秒
    isCreation(createTimeStr) {
      let createTime = this.getDateFromString(createTimeStr).valueOf()
      let currentTime = new Date().valueOf()
      let i = (currentTime - createTime) / 1000
      return i <= 60;
    },
    // //初始化socket连接
    // initClient() {
    //   // 连接选项
    //   const options = {
    //     clean: true, // true: 清除会话, false: 保留会话
    //     connectTimeout: 4000, // 超时时间
    //     username: 'mgywebc',
    //     password: 'mgy.2022',
    //   }
    //   const connectUrl = 'wss://mqtt.moguvps.com:8084/mqtt'
    //   this.client = mqtt.connect(connectUrl, options);
    //
    //   this.client.on('connect', () => {
    //     this.getOrder()
    //   })
    //
    //   this.client.on('error', (error) => {
    //     this.$message.error(error)
    //     console.log('连接失败:', error)
    //   })
    //
    //   this.client.on('reconnect', (error) => {
    //     this.$message.error(error)
    //     console.log('正在重连:', error)
    //   })
    //
    //   //接收到消息处理
    //   this.client.on('message', (topic, message) => {
    //     let index = topic.toString().lastIndexOf("\/");
    //     topic = topic.substring(index + 1, topic.length);
    //     console.log("接收到"+topic+"的消息",JSON.parse(message))
    //     //给数据状态赋值
    //     this.records.forEach(value => {
    //       if (value['vpsName'] === topic) {
    //         console.log("创建时间:",value["createTime"]);
    //         //TODO
    //         //服务器返回状态
    //         let resultState = JSON.parse(message.toString())['operationState']
    //         //本地缓存状态
    //         let cacheState = parseInt(sessionStorage.getItem(value['vpsName']))
    //         if (cacheState === 2) { //如果缓存开机中，返回已开机，设置开机
    //           if (resultState === 0) {
    //             value['operationState'] = resultState
    //             sessionStorage.setItem(topic, resultState)
    //           }
    //         } else if (cacheState === 3) { //如果缓存状态为关机中，实际上已关机
    //           //存储并缓存
    //           if (resultState === 1) {
    //             value['operationState'] = resultState
    //             sessionStorage.setItem(topic, resultState)
    //           }
    //
    //         } else if (cacheState === 6) { //如果状态为重启中
    //           let currentTime = new Date().valueOf()
    //           let restartTime = value.restartTime
    //           if ((currentTime - restartTime) / 1000 > 15 && resultState === 0) {
    //             value['operationState'] = resultState
    //             sessionStorage.setItem(topic, resultState)
    //           }
    //         } else if (cacheState === 5) { //如果缓存状态为重装系统中，实际上已开机
    //           if (resultState === 0) {
    //             value['operationState'] = resultState
    //             sessionStorage.setItem(topic, resultState)
    //           }
    //         } else {
    //           value['operationState'] = resultState
    //           sessionStorage.setItem(topic, resultState)
    //         }
    //       }
    //     })
    //
    //   })
    //
    // },
    //
    // //订阅一个vps
    // subscription(vpsName) {
    //   //连接成功
    //   this.client.subscribe(vpsName, {qos: 0}, (error) => {
    //     if (!error) {
    //       //订阅成功
    //       console.log("订阅成功:" + vpsName)
    //     } else {
    //       // 订阅失败
    //       console.log(error)
    //       console.log("订阅失败")
    //
    //     }
    //   })
    //
    // },
    // //取消订阅一个vps状态
    // unSubscription(vpsName) {
    //   //连接成功
    //   this.client.unsubscribe(vpsName, (error) => {
    //     if (!error) {
    //       //取消订阅成功
    //       console.log("取消订阅成功:" + vpsName)
    //     } else {
    //       console.log(error)
    //       // 取消订阅失败
    //       console.log("取消订阅失败")
    //     }
    //   })
    // },
    focusVpsRemark() {
      this.vpsRemarkFlag = true;
    },
    /*    blurVpsRemark() {
          this.vpsRemarkFlag = true;
        },*/

    handleClick(tab, event) {
      this.getOrder()
      this.chooseArea();
      if (tab.name === 'first'){
        this.mgyVpsPageParam.versionType = 1
      }
      if (tab.name === 'second'){
        this.mgyVpsPageParam.versionType = 2
      }
    },
    checkRemark(row) {
      this.mgyVpsRemarkParam.vpsId = row.id;
      this.mgyVpsRemarkParam.remark = row.remark;
      this.$http
          .post("/mgyVps/setRemark", this.mgyVpsRemarkParam)
          .then((res) => {
            if (res.data.data === true) {
              this.$message.success("修改成功");
            }
          });
    },
    onSubmit(row) {
      this.mgyVpsRemarkParam.vpsId = row.id;
      this.mgyVpsRemarkParam.remark = row.remark;
      this.$http
          .post("/mgyVps/setRemark", this.mgyVpsRemarkParam)
          .then((res) => {
            if (res.data.data === true) {
              this.$message.success("修改成功");
            }
          });
    },
    remarkVps(row) {
      console.log(row)
      this.vpsRemarkFlag = false;
    },
    setArea() {
      this.$http
          .post("/mgyVps/setPptpArea", this.mgyPptpAreaParam)
          .then((res) => {
            if (res.data.data === true) {
              this.getOrder();
            }
          });
    },
    onSureArea() {
      this.showArea = false;
      if (this.pptpAreaType === 1) {
        this.setArea();
      }
      if (this.pptpAreaType === 5) {
        this.batchSetArea();
      }
    },
    batchSetArea() {
      let vpsId = "";
      this.mgyBatchPptpAreaParam.vpsId.forEach((item) => {
        vpsId = vpsId + "," + item;
      });
      vpsId = vpsId.substring(1, vpsId.length);
      this.mgyPptpAreaParam.vpsId = vpsId;
      this.$http
          .post("/mgyVps/batchSetPptpArea", this.mgyPptpAreaParam)
          .then((res) => {
            if (res.data.data === true) {
              this.getOrder();
            }
          });
    },
    changeArea(e) {
      this.$nextTick(() => {
        let reg = new RegExp(" / ", "g");
        this.mgyPptpAreaParam.pptpArea = this.$refs["cascaderAddr"].presentText.replace(reg, "");
      });
      this.mgyPptpAreaParam.pptpHost = e[1];
    },
    //拨号区域
    dialog(e) {
      this.showArea = true;
      this.mgyPptpAreaParam.vpsId = e.id;
      this.pptpAreaType = 1;
    },
    chooseArea() {
      if (this.activeName === 'first'){
        this.$http.get("/mgyPptpServer/getPptpServerList").then((res) => {
          if (res.data.code === 200) {
            this.dialogList = res.data.data;
          }
        });
      }
      if (this.activeName === 'second'){
        this.$http.get("/mgyPptpServer/getPptpServerListNew").then((res) => {
          if (res.data.code === 200) {
            this.dialogList = res.data.data;
          }
        });
      }
    },
    goNewProduct(e) {
      if (this.activeName === 'first'){
        this.$router.push({
          path: "/product",
          query: {
            id: e.productId,
            type: '1',
            vpsId: e.id,
            versionType: 1
          },
        });
      }
      if (this.activeName === 'second'){
        this.$router.push({
          path: "/product",
          query: {
            id: e.productId,
            type: '3',
            vpsId: e.id,
            versionType: 2
          },
        });
      }

    },
    getDateFromString(str) {
      let reg = /^(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+)/;
      let s = str.match(reg);
      let result = "";
      if (s) {
        result = new Date(s[1], s[2] - 1, s[3], s[4], s[5], s[6]);
      }
      return result;
    },
    getOrder() {
      if (this.activeName === 'first'){
        this.mgyVpsPageParam.versionType= 1
      }
      if (this.activeName === 'second'){
        this.mgyVpsPageParam.versionType= 2
      }
      // this.listLoading = true
      this.$http
          .post("/mgyVps/getVpsUser", this.mgyVpsPageParam)
          .then((res) => {
            if (res.data.code === 200) {
              //取消所有订阅
              // if (this.subscriptionList.length) this.unSubscription(this.subscriptionList)
              //给表格数据重新赋值
              this.records = res.data.data.records;
              this.total = res.data.data.total;
              // setTimeout(() => {
              //   this.listLoading = false
              // }, 0.5 * 1000)
              //批量订阅
              // this.subscriptionList = []
              // this.records.forEach(value => {
              //   this.subscriptionList.push(`vps_status/${value['vpsName']}`)
              //   let item = sessionStorage.getItem(value['vpsName']);
              //   if (item !== null) { //如果有缓存，直接给数据赋值
              //     value['operationState'] = parseInt(item)
              //   } else { //没有缓存 获取中状态
              //     value['operationState'] = -1
              //   }
              // })
              // this.subscription(this.subscriptionList)

            } else if (res.data.data === "请先进行实名认证！"){
              this.$message.error(res.data.data);
              this.$router.push('/realName')
            } else {
              this.$message.error("服务端错误");
            }
          })
          .catch(() => {

          });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    open(e, index) {
      //打开vps
      if (e.operationState !== index) {
        if (e.operationState === 0) {
          return this.$message.error("vps正在运行中");
        }
        if (e.operationState === 2) {
          return this.$message.error("vps处于正在开机中");
        }
        if (e.operationState === 3) {
          return this.$message.error("vps处于正在关机中");
        }
      }
      this.currentVpsName = e['vpsName']
      this.title = "您确定要开机吗？";
      this.tabIndex = index;
      this.centerDialogVisible = true;
      this.item = e;
    },
    close(e, index) {
      this.tabIndex = index;
      //关机vps
      if (e.operationState !== index) {
        if (e.operationState === 1) {
          return this.$message.error("vps已关机");
        }
        if (e.operationState === 2) {
          return this.$message.error("vps处于正在开机中");
        }
        if (e.operationState === 3) {
          return this.$message.error("vps处于正在关机中");
        }
      }
      this.currentVpsName = e['vpsName']
      this.title = "您确定要关机吗？";
      this.centerDialogVisible = true;
      this.item = e;
    },
    forcedClose(e) {
      if (e.operationState === 1) {
        return this.$message.error("vps已关机")
      }
      this.$confirm('确认强制关机？')
          .then(_ => {
            this.$http
                .post(`/mgyOperation/forcedShoutDown/${e.id}`)
                .then(() => {
                  this.item = "";
                  //缓存并更新为关机中
                  sessionStorage.setItem(this.currentVpsName, '3') //关机中
                  this.records.forEach(value => {
                    if (value['vpsName'] === e.vpsName) {
                      value['operationState'] = 3
                    }
                  })
                });
          })
          .catch(_ => {});

    },
    onSure() {
      if (this.tabIndex === 1) { //开机
        this.openVps();
        this.centerDialogVisible = false;
      }
      if (this.tabIndex === 0) { //关机
        this.closeVps();
        this.centerDialogVisible = false;
      }
      if (this.tabIndex === 4) { //自动续费
        this.autoRenewVps();
        this.autoRenewDialogVisible = false;
      }
      if (this.tabIndex === 2) { //重启
        this.centerDialogVisible = false;
        this.restartVps();
      }
      if (this.tabIndex === 6) { //批量自动续费
        this.autoRenewDialogVisible = false;
        this.batchAutoRenew()
        setTimeout(() =>{
          location.reload()
        },200)
        console.log("reload======")
      }
      if (this.tabIndex === 3) { //重装系统
        if (this.mirrorId === "") {
          return this.$message.error("请选择镜像");
        }
        if (this.reLoadItem.vpsPassword === "") {
          return this.$message.error("请填写vps密码");
        }
        this.reLoadItem.mirrorId = this.mirrorId;
        this.centerDialog = false;
        this.reloadVps();
      }
    },
    openVps() {
      this.$http
          .post(`/mgyOperation/startOperation/${this.item.id}`)
          .then(() => {
            this.item = "";
            sessionStorage.setItem(this.currentVpsName, '2') //设置为开机中
            this.records.forEach(value => {
              if (value['vpsName'] === this.currentVpsName) {
                value['operationState'] = 2
              }
            })
          });
    },
    autoRenewVps(){
      //单个自动续费 调用接口
      this.$http
          .post(`/mgyVps/autoRenew`,this.autoRenewParam)
          .then((res) => {
            if (res.code === 200) {
              this.$notify({
                title: 'Success',
                message: '设置成功',
                type: 'success',
                duration: 2000
              })
            }
          });
      this.getOrder();
      this.autoRenewParam.vpsIds=[]
    },
    closeVps() {
      this.$http
          .post(`/mgyOperation/closeOperation/${this.item.id}`)
          .then(() => {
            this.item = "";
            //缓存并更新为关机中
            sessionStorage.setItem(this.currentVpsName, '3') //关机中
            this.records.forEach(value => {
              if (value['vpsName'] === this.currentVpsName) {
                value['operationState'] = 3
              }
            })
          });
    },
    restart(e, index) {
      this.currentVpsName = e['vpsName']
      if (e.operationState === 1 || e.operationState === 3) {
        return this.$message.error("关机状态下不能重启");
      }
      this.tabIndex = index;
      this.centerDialogVisible = true;
      this.item = e;
      this.title = "您确定要重启吗？";
    },
    autoRenew(e,index){
      this.autoRenewDialogVisible=true
      this.tabIndex = index;
      this.autoRenewParam.vpsIds.push(e.id)
    },
    autoRenewBatch(){
      this.autoRenewDialogVisible=true
      this.tabIndex = 6;
    },
    reload(e, index) {
      if (e['operationState'] !== 1) {
        return this.$message.error("请先关闭vps")
      }
      this.currentVpsName = e['vpsName']
      this.getMirror(e.id);
      this.tabIndex = index;
      this.centerDialog = true;
      this.reLoadItem.vpsId = e.id;
    },
    restartVps() {
      this.$http
          .post(`/mgyOperation/restartOperation/${this.item.id}`)
          .then((res) => {
            this.item = "";
            this.mirrorId = "";
            if (res.data.code === 200) {
              // this.$message.success(res.data.message);
              sessionStorage.setItem(this.currentVpsName, '6')
              this.records.forEach(value => {
                if (value['vpsName'] === this.currentVpsName) {
                  value.restartTime = new Date().valueOf()
                  value.operationState = 6
                }
              })
            }
          });
    },
    getMirror(vpsId) {
      this.mgyMirrorPageParam.vpsId = vpsId;
      this.$http
          .post("/mgyMirror/getPageListByUser", this.mgyMirrorPageParam)
          .then((res) => {
            this.recordsMirror = res.data.data.records;
          });
    },
    change(e) {
      this.mirrorId = e.id;
    },
    reloadVps() {
      let item = this.records.filter(item => item.id === this.reLoadItem.vpsId)
      if (item[0].operationState !== 1) {
        this.$message.error("请先关闭服务器");
        return false
      }
      this.$http
          .post("/mgyOperation/reinstallOperation", this.reLoadItem)
          .then((res) => {
            this.mirrorId = ""
            this.reLoadItem.vpsId = ""
            this.reLoadItem.mirrorId = ""
            this.reLoadItem.vpsPassword = ""
            this.value = ""
            if (res.data.code === 200) {
              // this.$message.success("请求已提交，请耐心等待");
              //缓存并更新为重装系统中
              sessionStorage.setItem(this.currentVpsName, '5')
              this.records.forEach(value => {
                if (value['vpsName'] === this.currentVpsName) {
                  value['operationState'] = 5
                }
              })
              // this.getOrder()
              // this.multpleMirrorDialog = false
            } else {
              this.$message.error(res.data.data);
            }
          });
    },
    restartMultiple(e) {
      if (this.multipleSelection.length === 0) {
        return this.$message.error("请选择需要操作的vps");
      } else {
        if (e === 2) { //批量重启
          for (let i = 0; i < this.multipleSelection.length; i++) {
            let value = this.multipleSelection[i];
            if (value.state !== 0 || value.operationState !== 0) {
              return this.$message.error("开机状态下才能重启");
            }
          }
          this.title = "您确定要重启吗？";
          this.multipleDialogVisible = true;
          this.tabIndex = e;
        } else if (e === 3) { //批量重装系统
          for (let i = 0; i < this.multipleSelection.length; i++) {
            let value = this.multipleSelection[i];
            if (value.state !== 0 || value.operationState !== 1) {
              return this.$message.error("关机状态下才能重装系统");
            }
          }
          let productId = this.multipleSelection[0].productId;
          let flag = true;
          this.multipleSelection.forEach((item) => {
            if (productId !== item.productId) {
              flag = false;
              return this.$message.error("请选择相同产品类型的vps！");
            }
          });
          if (flag) {
            this.getMirror(this.multipleSelection[0].id);
            this.multpleMirrorDialog = true;
            this.tabIndex = e;
          }
        }
      }
    },
    batchRenewal() {
      if (this.multipleSelection.length === 0) {
        return this.$message.error("请选择需要操作的vps");
      } else {
        //判断选择的vps是否同一个产品
        let productId = this.multipleSelection[0].productId;
        let flag = true;
        this.multipleSelection.forEach((item) => {
          if (productId !== item.productId) {
            flag = false;
            return this.$message.error("请选择相同产品类型的vps！");
          }
        });
        if (flag) {
          this.mgyBatchOperationParam.vpsIds = this.multipleSelection.map(
              (v) => v.id
          );
          let vpsId = "";
          this.mgyBatchOperationParam.vpsIds.forEach((item) => {
            vpsId = vpsId + "," + item;
          });
          let num = this.mgyBatchOperationParam.vpsIds.length;
          vpsId = vpsId.substring(1, vpsId.length);

          if (this.activeName === 'first'){
            this.$router.push({
              path: "/product",
              query: {
                id: productId,
                type: '2',
                num: num.toString(),
                vpsId: vpsId,
                versionType: 1
              },
            });
          }
          if (this.activeName === 'second'){
            this.$router.push({
              path: "/product",
              query: {
                id: productId,
                type: '4',
                num: num.toString(),
                vpsId: vpsId,
                versionType: 2
              },
            });
          }
        }
      }
    },
    batchAutoRenew(){
      //批量自动续费
      if (this.multipleSelection.length === 0) {
        return this.$message.error('请选择需要操作的vps')
      } else {
        // this.autoRenewDialogVisible = true
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const value = this.multipleSelection[i]
          this.autoRenewParam.vpsIds.push(value.id)
        }
      }
      this.$http
          .post(`/mgyVps/autoRenew`,this.autoRenewParam)
          .then((res) => {
              if (res.code === 200) {
                  this.getOrder();
              }
          })
      this.autoRenewParam.vpsIds=[]
    },
    batchSetPptpArea() {
      if (this.multipleSelection.length === 0) {
        return this.$message.error("请选择需要操作的vps");
      } else {
        this.mgyBatchPptpAreaParam.vpsId = this.multipleSelection.map(
            (v) => v.id
        );
        this.showArea = true;
        this.pptpAreaType = 5;
      }
    },
    openMultiple() {
      if (this.multipleSelection.length === 0) {
        return this.$message.error("请选择需要操作的vps");
      } else {
        let valIndex = this.multipleSelection.findIndex((e) => {
          return e.operationState !== 1;
        });
        let varIndex = this.multipleSelection.findIndex((e) => {
          return e.state === 1;
        });
        if (valIndex !== -1) {
          return this.$message.error("只有关机的vps才能启动");
        }
        if (varIndex !== -1) {
          return this.$message.error("已过期vps不能启动");
        }
        this.showDialog(1);
      }
    },
    closeMultiple() {
      if (this.multipleSelection.length === 0) {
        return this.$message.error("请选择需要操作的vps");
      } else {
        let varIndex = this.multipleSelection.findIndex((e) => {
          return e.state === 1;
        });
        if (varIndex !== -1) {
          return this.$message.error("已过期vps无法操作");
        }
        let valIndex = this.multipleSelection.findIndex((e) => {
          return e.operationState !== 0;
        });
        if (valIndex !== -1) {
          this.$message.error("只有开机的vps才可以关机");
        } else {
          this.showDialog(0);
        }
      }
    },
    showDialog(e) {
      this.tabIndex = e;
      this.multipleDialogVisible = true;
      if (e === 1) {
        this.title = "您确定要开机吗？";
      }
      if (e === 0) {
        this.title = "您确定要关机吗？";
      }
    },
    multipleSure() {
      this.title = "";
      if (this.tabIndex === 0) {
        this.multipleDialogVisible = false;
        this.mgyBatchOperationParam.vpsIds = this.multipleSelection.map(
            (v) => v.id
        );
        this.mgyBatchOperationParam.type = 0;
        this.multipleOprate();

      }
      if (this.tabIndex === 1) {
        this.multipleDialogVisible = false;
        this.mgyBatchOperationParam.vpsIds = this.multipleSelection.map(
            (v) => v.id
        );
        this.mgyBatchOperationParam.type = 1;
        this.multipleOprate();
      }
      if (this.tabIndex === 2) { //批量重启确认按钮点击
        let startIndex = this.multipleSelection.findIndex((e) => {
          return e.state === 1;
        });
        if (startIndex !== -1) {
          this.$message.error("重装系统失败");
        } else {
          this.mgyBatchOperationParam.vpsIds = this.multipleSelection.map(
              (v) => v.id
          );
          this.mgyBatchOperationParam.type = 2;

          this.multipleOprate();
        }
        this.multipleDialogVisible = false;
      }
      if (this.tabIndex === 3) {
        if (this.mirrorId === "") {
          return this.$message.error("请选择镜像");
        }
        if (this.password === "") {
          return this.$message.error("填写vps密码");
        }

        this.multpleMirrorDialog = false;
        this.mgyBatchOperationParam.vpsIds = this.multipleSelection.map(
            (v) => v.id
        );
        this.mgyBatchOperationParam.mirrorIds = this.multipleSelection.map(
            () => this.mirrorId
        );
        this.mgyBatchOperationParam.vpsPasswords = this.multipleSelection.map(
            () => this.password
        );
        this.mgyBatchOperationParam.type = 3;
        this.multipleOprate();
      }
    },
    multipleOprate() {
      let type = this.mgyBatchOperationParam.type
      let vpsIds = this.mgyBatchOperationParam.vpsIds
      console.log("批量操作名称:", this.mgyBatchOperationParam)
      this.records.forEach(value => {
        if (vpsIds.includes(value.id)) { //如果包含
          switch (type) {
            case 0: //批量关机
              value['operationState'] = 3
              sessionStorage.setItem(value['vpsName'], '3')
              break
            case 1: //批量开机
              value['operationState'] = 2
              sessionStorage.setItem(value['vpsName'], '2')
              break
            case 2: //批量重启
              value['restartTime'] = new Date().valueOf()
              value['operationState'] = 6
              sessionStorage.setItem(value['vpsName'], '6')
              break
            case 3: //批量重装系统
              value['operationState'] = 5
              sessionStorage.setItem(value['vpsName'], '5')
              break
          }

        }
      })
      this.$http
          .post("/mgyOperation/batchOperation", this.mgyBatchOperationParam)
          .then((res) => {
            this.mirrorId = "";
            this.mgyBatchOperationParam = {};
            if (res.data.code === 200) {
              this.$message.success("操作成功");
            } else {
              this.$message.error(res.data.data);
            }
          });
    },
    handleSizeChange(val) {
      this.mgyVpsPageParam.pageSize = val;
      this.getOrder();
    },
    handleCurrentChange(val) {
      this.mgyVpsPageParam.pageIndex = val;
      this.getOrder();
    },
    modify(e) {
      this.vpsUpdatePasswordParam.vpsId = e.id;
      this.showCertification = true;
    },
    getCertification() {
      if (this.vpsUpdatePasswordParam.password === "") {
        return this.$message.error("请填写密码");
      } else {
        let reg =
            /^(?![a-zA-Z]+$)(?![A-Z\d]+$)(?![A-Z\W_]+$)(?![a-z\d]+$)(?![a-z\W_]+$)(?![\d\W_]+$)[a-zA-Z\d\W_]{8,20}$/;
        if (this.vpsUpdatePasswordParam.password.match(reg)) {
          this.showCertification = false;
          this.$http
              .post("/mgyVps/updateOperation", this.vpsUpdatePasswordParam)
              .then((res) => {
                if (res.data.code === 200) {
                  this.$message.success(res.data.message);
                  this.getOrder();
                } else {
                  this.$message.error("系统错误");
                }
              });
        } else {
          return this.$message.error(
              "密码为大小写字母、数字、特殊符号的组合，至少包含三种，长度 8-20 位"
          );
        }
      }
    },
    goProduct() {
      this.$router.push("/host");
    },
    exportExcel() {
      let tables = document.getElementById("table1");
      let table_book = XLSX.utils.table_to_book(tables);
      var table_write = XLSX.write(table_book, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([table_write], { type: "application/octet-stream" }),
          "moguvps.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, table_write);
      }
      return table_write;
    },
  },
};
</script>
<style>
.tabs-my .el-tabs__header {
  margin-top: 10px;
  height: 50px; 
  width: 200px;
}
.area-text {
  font-size: 20px;
}
.tabs-my .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav{
  height: 50px; 
}
.el-form-item {
  margin-bottom: 0;
}
.remark-icon {
  width: 22px;
  height: 28px;
  float: right;
  position: relative;
  margin-top: -25px;
  margin-right: 11px;
  z-index: 9999;
}
/*.remarkA {
  border: 1px solid #e6e6e6;
  padding-left: 5px;
}*/

.certificationInput .el-input__icon.el-icon-view.el-input__clear {
  margin-left: 150px;
}

.el-cascader-panel {
  height: 550px;
}

.el-scrollbar__wrap {
  height: 100%;
}

.el-cascader-menu {
  min-width: 200px;
}
</style>
