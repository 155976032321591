<template>
  <div>
    <el-card>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>充值记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <el-table :data="orderlist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="订单号" prop="chargeNo"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="充值金额" prop="chargeAmount"></el-table-column>
        <el-table-column label="赠送金额" prop="givingAmount"></el-table-column>

        <el-table-column label="支付" prop="">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" v-if="scope.row.state == 0"
              >未支付</el-button
            >
            <el-button size="mini" type="primary" v-if="scope.row.state == 1"
              >已支付</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.mgyChargeRecordPageParam.pageIndex"
          :page-sizes="[5, 10, 20, 30]"
          :page-size="this.mgyChargeRecordPageParam.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    </el-card>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "product",
  components: {},
  data() {
    return {
      mgyChargeRecordPageParam: {
        pageIndex: 1,
        pageSize: 5,
      },
      orderlist: [],
      total: 0,
    };
  },
  mounted() {
    this.getPayList();
  },
  methods: {
    getPayList() {
      this.$http
        .post(
          "/mgyChargeRecord/getChargeRecordList",
          this.mgyChargeRecordPageParam
        )
        .then((res) => {
          console.log(res);
          this.orderlist = res.data.data.records;
          this.total = res.data.data.total;
        });
    },
    handleSizeChange(val) {
      this.mgyChargeRecordPageParam.pageSize = val;
      this.getPayList();
    },
    handleCurrentChange(val) {
      this.mgyChargeRecordPageParam.pageIndex = val;
      this.getPayList();
    },
  },
};
</script>
<style></style>
