<template>
  <div class="">
    <div class="form" v-html="content"></div>
  </div>
</template>
<script>
import Loading from "./../components/loading";
export default {
  name: "alipay",
  components: {
    Loading,
  },
  data() {
    return {
      data: "",
      content: "",
      loading: true,
    };
  },
  created() {
    this.content = this.$route.query.content;
    console.log(this.content);
    setTimeout(() => {
      document.forms[0].submit();
    }, 100);
  },
  methods: {
    paySubmit() {
      this.axios
        .post("/pay", {
          orderId: this.orderId,
          orderName: "Vue高仿小米商城",
          amount: 0.01, //单位元
          payType: 1, //1支付宝，2微信
        })
        .then((res) => {
          this.content = res.content;
          setTimeout(() => {
            document.forms[0].submit();
          }, 100);
        });
    },
  },
};
</script>
<style>
.alipay {
  width: 200px;
  height: 200px;
  background: #fff;
}
</style>
