<template>
  <div>
    <!-- 注册 -->
    <nav-header></nav-header>
    <div class="sign-box">
      <div class="sign-form-box register-box clearfix">
<!--        <div class="register-title clearfix">-->
<!--          <h2>-->
<!--            <span class="inline-block icon-reg-mode icon-reg-phone vam"></span-->
<!--            ><span class="vam">账号密码注册</span>-->
<!--          </h2>-->
<!--          <h2 class="hide">-->
<!--            <span class="inline-block icon-reg-mode icon-reg-wx vam"></span-->
<!--            ><span class="vam">微信快速注册</span>-->
<!--            <span class="reg-mode-wrapper inline-block"-->
<!--              >切换成<span class="reg-mode-text">账号密码注册></span></span-->
<!--            >-->
<!--          </h2>-->
<!--          <span class="go-login"-->
<!--          @click=goLogin()-->
<!--            >已经拥有账号？<a href="javascript:;">账号登录</a></span-->
<!--          >-->
<!--        </div>-->
        <div class="register-space clearfix"></div>
        <div id="account-register" class="register-main">
          <form
            action="/register/reg.html"
            name="register"
            id="register"
            method="post"
            onsubmit="return false"
          >
            <div class="register-item-box">
              <div class="register-item">
                <label
                  >手机号码<span>*</span
                  ><input
                    v-model="userRegisterParam.account"
                    type="number"
                    name="mobile"
                    id="mobile"
                    maxlength="11"
                    placeholder="请输入手机号码"
                    autofocus
                /></label>
              </div>
              <div id="mobileTip"></div>
            </div>
            <div class="register-item-box">
              <div class="register-item">
                <label
                  >短信验证码<span>*</span
                  ><input
                    v-model="userRegisterParam.code"
                    type="text"
                    name="mobilecode"
                    id="mobilecode"
                    maxlength="6"
                    placeholder="请输入短信验证码"
                  />
                  <div id="captchaTwo"></div>
                  <input
                    v-show="showTime"
                    @click='closeDialog("open")'
                    type="button"
                    class="send-captcha"
                    v-model="text"
                  />
                  <input
                    v-if="!showTime"
                    type="button"
                    class="send-captcha"
                    v-model="s"
                  />
                </label>
              </div>
              <div id="mobilecodeTip"></div>
            </div>

            <!-- 编辑界面 -->
            <el-dialog :title="title" :visible.sync="systemCodeVisible" width="20%" @click='closeDialog("close")'>

              <div class="block" v-for="fit in fits" :key="fit">
                <span class="demonstration"></span>
                <el-image
                    style="width: 100px; height: 100px"
                    :src="verifyUrl"
                    :fit="fit"
                    @click="refresh"
                ></el-image>
              </div>
              <el-input v-model="userRegisterParam.verifyCode" placeholder="请输入验证码"></el-input>

              <div slot="footer" class="dialog-footer">
                <el-button size="small" @click='closeDialog("close")'>取消</el-button>
                <el-button size="small" type="primary" class="title" @click='getCode'>确定</el-button>
              </div>
            </el-dialog>

            <input
            @click="verifyCode"
              id="submitBtn"
              type="submit"
              value="验证登录"
              class="reg-submit"
            />
          </form>
        </div>
      </div>
    </div>
    <div class="security_model">
      <el-dialog
        title="用户注册协议"
        :visible.sync="security_text_flag"
        width="45%"
        center
      >
        <div class="security_model_text">
          <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本协议是您与蘑菇云相关事宜所订立的契约，请您仔细阅读本注册协议，您点击“同意并继续”后，本协议即构成对双方有约束力的法律文件。</h3>
          <h2>一.用户注册</h2>
          <p>a. 用户注册是指用户登录蘑菇云，按要求填写相关信息并确认同意本服务协议的过程。</p>
          <p>b. 蘑菇云用户必须是具有完全民事行为能力的自然人。</p>
          <p>c. 境外网络无法使用本软件，请使用大陆网络。</p>
          <p>d. 本产品为虚拟类产品一经充值，概不退款。</p>
          <p>e. 所有套餐价格、产品价格都不含税。</p>
          <p>f. 如因客户自身问题不能实名制，概不退款</p>
          <p>g. 本产品网络已屏蔽腾讯、新浪、贴吧业务。</p>
          <p>h. 本产品网络不提供公网IP。</p>
          <h2>二.用户的帐号，密码和安全性</h2>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;用户一旦注册成功，成为本站的合法用户。蘑菇云将对用户名和密码安全负全部责任。此外，每个用户都要对以其用户名进行的所有活动和事件负全责。用户若发现任何非法使用用户帐号或存在安全漏洞的情况，请立即通告本站。</p>
          <h2>三.用户依法言行义务</h2>
          <p>本协议依据国家相关法律法规规章制定，用户同意严格遵守以下义务：</p>
          <p>(1) 不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；</p>
          <p>(2) 从中国大陆向境外传输资料信息时必须符合中国有关法规；</p>
          <p>(3) 不得利用本站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</p>
          <p>(4) 不得干扰本站的正常运转，不得侵入本站及国家计算机信息系统；</p>
          <p>(5) 不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；</p>
          <p>(6) 不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；</p>
          <p>(7) 不得教唆他人从事本条所禁止的行为；</p>
          <p>(8) 不得利用在本站注册的账户进行牟利性经营活动；</p>
          <p>(9) 不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；</p>
          <p>用户应不时关注并遵守本站不时公布或修改的各类合法规则规定。</p>
          <p>本站保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。</p>
          <p>若用户未遵守以上规定的，本站有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。</p>
          <h2>四.协议更新及用户关注义务</h2>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;根据国家法律法规变化及网站运营需要，蘑菇云有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本站上即生效，并代替原来的协议。用户可随时登录查阅最新协议； 用户有义务不时关注并阅读最新版的协议及网站公告。如用户不同意更新后的协议，可以且应立即停止接受网站依据本协议提供的服务；如用户继续使用本网站提供的服务的，即视为同意更新后的协议。蘑菇云建议您在使用本站之前阅读本协议及本站的公告。 如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</p>
          <h2>五.法律管辖和适用</h2>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。 如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。 如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼。</p>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="confirm"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>

    <!--底部-->
  </div>
</template>

<script>
import NavHeader from "./../components/nav-header.vue";
export default {
  name: "index",
  components: { NavHeader },
  data() {
    return {
      text: "获取验证码",
      showTime: true,
      setTime: null,
      title: "系统验证码",
      s: 60,
      security_flag: false,
      security_text_flag: false,
      systemCodeVisible: false,
      verifyUrl: "",
      fits: ['fill'],
      getSmsCodeParam:{
        account: "",
        verifyCode: "",
        uuid: "",
      },
      userRegisterParam: {
        verifyCode: "",
        account: "",
        password: "",
        code: "",
        uuid: "",
      },
      password:''
    };
  },
  created() {
    this.$http
        .get('/verifyCode/getSystemCode')
        .then((res) => {
          this.verifyUrl = res.data.image
          this.userRegisterParam.uuid = res.data.uuid
        });
  },
  methods: {
    cancel(){
      this.security_text_flag = false;
      this.security_flag = false;
    },
    confirm(){
      this.security_text_flag = false;
      this.security_flag = true;
    },
    secureity() {
      this.security_text_flag = true;
    },
    refresh() {
      this.$http
          .get('/verifyCode/getSystemCode')
          .then((res) => {
            this.verifyUrl = res.data.image
            this.userRegisterParam.uuid = res.data.uuid
          });
    },
    // 关闭编辑、增加弹出框
    closeDialog(dialog) {
      if (dialog == 'close') {
        this.systemCodeVisible = false
      }else if (dialog == 'open'){
        this.systemCodeVisible = true
      }
    },
    getCode() {
      if (this.userRegisterParam.verifyCode === ''){
        return this.$message.error('请输入系统验证码')
      }
      if(this.s != 60){
        return this.$message.error('请稍后再试')
      }
      let phoneStatus = this.checkPhone(this.userRegisterParam.account);
      if (!phoneStatus) {
        this.$message.error("请输入正确手机号");
      }
      this.$http
          .post(`/sms/getSmsCode`,this.userRegisterParam)
          .then((res) => {
            if(res.data.code == 200){
              this.$message.success('获取验证码成功')
              this.systemCodeVisible = false
            }else if(res.data.code == 5109){
              this.s = 1
              this.$message.error("系统验证码输入错误");
            }else if(res.data.code == 5110){
              this.s = 1
              this.$message.error("未检测到系统验证码");
            }else {
              this.$message.error("获取验证码失败");
            }
            this.setTime = setInterval(() => {
              this.countDown();
            }, 1000);
          });
    },
    countDown() {
      if (this.s > 0) {
        this.showTime = false;
        this.s -= 1;
      } else if (this.s == 0) {
        this.handclick();
        this.showTime = true;
      }
    },
    handclick() {
      console.log(this.showTime)
      this.text = "重新获取";
      this.s = 60;
      clearInterval(this.setTime);
      this.setTime = null;
    },
    verifyCode(){
      this.$http.post('/mgyUser/verify',this.userRegisterParam).then(res =>{
        this.userRegisterParam.password = ''
        this.password = ''
        //  console.log(res)
        if(res.data.code === 200){
        //   this.$message({
        //     type:'success',
        //     message:'您已注册',
        //     duration:3000
        //   })
          window.sessionStorage.setItem('token', res.data.token)
          
          this.$router.push('/login')
        }else{
          this.$message.error(res.data.data)
        }
      })
    },
  },
};
</script>
<style>
.security_model_text h2{
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 5px;
}
.security_model_text p{
  margin-bottom: 2px;
  font-size: 16px;
}
.security_model .el-dialog__title{
  font-size: 28px;
  font-weight: bolder;
}
.security_model .el-dialog__body{
  height: 500px;
  overflow: auto;
}
.security_title {
  color: #606266;
  cursor: pointer;
}
#captchaTwo {
  width: 400px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 45px;
}

#captchaTwo label {
  vertical-align: top;
  display: inline-block;
  width: 80px;
  text-align: right;
}

.register-item label .geetest_radar_tip_content {
  border: none;
  color: #18a452;
}

#captchaTwo .geetest_success_radar_tip_timeinfo {
  border: none;
}

#captchaTwo .geetest_success_radar_tip_content {
  border: none;
}

#text {
  height: 42px;
  width: 400px;
  text-align: center;
  border-radius: 2px;
  background-color: #f3f3f3;
  color: #bbbbbb;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 42px;
}

#wait {
  display: none;
  height: 42px;
  width: 400px;
  text-align: center;
  border-radius: 2px;
  background-color: #f3f3f3;
}

.loading {
  margin: auto;
  width: 70px;
  height: 20px;
}

.loading-dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 18px 4px;
  background: #ccc;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  opacity: 0;

  -webkit-box-shadow: 0 0 2px black;
  -moz-box-shadow: 0 0 2px black;
  -ms-box-shadow: 0 0 2px black;
  -o-box-shadow: 0 0 2px black;
  box-shadow: 0 0 2px black;

  -webkit-animation: loadingFade 1s infinite;
  -moz-animation: loadingFade 1s infinite;
  animation: loadingFade 1s infinite;
}

.loading-dot:nth-child(1) {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
}

.loading-dot:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.loading-dot:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loading-dot:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes loadingFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes loadingFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}
@import "../assets/css/register.css";
</style>
