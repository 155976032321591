import { render, staticRenderFns } from "./Contral.vue?vue&type=template&id=45ef7288&scoped=true&"
import script from "./Contral.vue?vue&type=script&lang=js&"
export * from "./Contral.vue?vue&type=script&lang=js&"
import style0 from "./Contral.vue?vue&type=style&index=0&id=45ef7288&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ef7288",
  null
  
)

export default component.exports