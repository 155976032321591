<template>
  <div>
    <nav-header></nav-header>
    <div class="login-main-area">
      <div class="container login-inner border-box">
        <div class="clearfix">
          <div class="login-desc">
            <h2>数十万用户的信赖之选</h2>
            <p>节点遍布国内主要区域</p>
            <p>全动态BGP网络</p>
            <p>至强硬件打造强悍性能体验</p>
            <p>技术服务7×24全天响应</p>
          </div>
          <!-- end -->
          <div
            id="passwordLogin"
            class="login-main pie-for-element login-content"
          >
            <div class="login-title">用户登录</div>
            <p class="error-info" id="errorInfo"></p>
            <div class="login-form-item form-item-bg form-item-bg-user">
              <input
                type="text"
                name="username"
                placeholder="手机号"
                id="userName"
                class="input-focus-handle"
                v-model="userLoginParam.account"
                autocomplete="off"
              />
              <div id="emailAutoComplete" class="email-complete-list"></div>
            </div>
            <div
              class="
                login-form-item
                form-item-bg form-item-bg-lock
                password-input-wrap
              "
            >
              <input
                id="passwordInput"
                type="password"
                name="password"
                placeholder="登录密码"
                v-model="userLoginParam.password"
                class="input-focus-handle"
                autocomplete="off"
              />
              <div class="caps-lock-remind" id="capsLockRemind">
                大写锁定已打开
              </div>
            </div>
            <div
              class="login-form-item"
              id="jyCaptcha"
              style="position: relative; border: none"
            >
              <div id="captchaTwo">
                <div id="text">行为验证™ 安全组件加载中</div>
                <div id="wait" class="show">
                  <div class="loading">
                    <div class="loading-dot"></div>
                    <div class="loading-dot"></div>
                    <div class="loading-dot"></div>
                    <div class="loading-dot"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="login-options">
              <!--<label for="usernameSave" class="idsave-check">-->
              <!--<span class="checkbox-icon" id="checkboxTrigger"></span>-->
              <!--<input type="checkbox" id="usernameSave" name="saveNull" value="">-->
              <!--记住登录名-->
              <!--</label>-->
              <label for="userZkuss" class="idsave-check" id="userZkussLabel">
              </label>
              <router-link to="/refresh">
                <a href="javascript:;">忘记密码？</a>
               </router-link>
            </div>
            <input
              @click="goLogin"
              type="submit"
              value="登 录"
              id="loginSubmit"
              class="login-submit default-transition default-font"
            />
            <div class="go-reg">
              还没有账号？立即<router-link to="/register"
                ><a href="javascript:;">免费注册</a></router-link
              >
            </div>
          </div>
        </div>
        <div class="bg-change"></div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/nav-header.vue";
export default {
  name: "login",
  components: {
    NavHeader,
  },
  data() {
    return {
      userLoginParam: {
        account: "",
        password: "",
      },
    };
  },
  methods: {
    goLogin() {
      let phoneStatus = this.checkPhone(this.userLoginParam.account);
      if (!phoneStatus) {
        return this.$message.error("请输入正确手机号");
      }
      this.userLoginParam.password = this.$md5(this.userLoginParam.password);
      this.$http.post("/mgyUser/login", this.userLoginParam).then((res) => {
        this.userLoginParam.password = "";
        if (res.data.code == 200) {
          this.$store.commit("xLogin", res.data.data);
          this.$message({
            type:'success',
            message:'登录成功',
            duration:3000
          })
          this.$router.push('/index')
        }else if (res.data.code == 4005) {
          // this.$store.commit("xLogin", res.data.data);
          this.$message({
            type:'success',
            message:'异地登录，请验证信息',
            duration:3000
          })
          this.$router.push('/verify')
        }
        else {
          this.$message.error(res.data.data);
        }
      });
    },
  },
};
</script>

<style>
body {
  background: #2c2c2c !important;
}
#jyCaptcha {
  display: none;
}
#captchaTwo {
  position: absolute;
  left: 0;
}

#captchaTwo label {
  vertical-align: top;
  display: inline-block;
  width: 80px;
  text-align: right;
}
.register-item label .geetest_radar_tip_content {
  border: none;
}
#captchaTwo .geetest_success_radar_tip_timeinfo {
  border: none;
}
#captchaTwo .geetest_success_radar_tip_content {
  border: none;
}
#text {
  height: 38px;
  width: 300px;
  text-align: center;
  border-radius: 2px;
  background-color: #f3f3f3;
  color: #bbbbbb;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 38px;
}

#wait {
  display: none;
  height: 38px;
  width: 300px;
  text-align: center;
  border-radius: 2px;
  background-color: #f3f3f3;
}

.loading {
  margin: auto;
  width: 70px;
  height: 20px;
}

.loading-dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 18px 4px;
  background: #ccc;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  opacity: 0;

  -webkit-box-shadow: 0 0 2px black;
  -moz-box-shadow: 0 0 2px black;
  -ms-box-shadow: 0 0 2px black;
  -o-box-shadow: 0 0 2px black;
  box-shadow: 0 0 2px black;

  -webkit-animation: loadingFade 1s infinite;
  -moz-animation: loadingFade 1s infinite;
  animation: loadingFade 1s infinite;
}

.loading-dot:nth-child(1) {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
}

.loading-dot:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.loading-dot:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loading-dot:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}
.login-content {
  display: block;
}
.verify-content {
  display: none;
}
.verify-content > form {
  margin-top: 26px;
}
.verify-content > .error-info {
  margin-bottom: 26px;
}
.verify-content input.send-captcha {
  /* position: absolute; */
  /* top: 2px;
	right: 2px; */
  width: 164px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  text-indent: 0;
  border: 1px solid #059fff;
  background-color: #059fff;
  cursor: pointer;
  margin-left: 8px;
}
.verify-content input.send-captcha-disabled {
  /* width: 140px; */
  border: 1px solid #ccc;
  background-color: #ccc;
}
#changeLoginType {
  cursor: pointer;
}
.foot-copyright {
  background: #2c2c2c;
  border-top: none;
}
@import "../assets/css/login.css";
</style>
