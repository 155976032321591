<template>
  <div>
    <el-card v-if="showTitle">
     <div >
        <div v-if="this.user.isReal == true">
        <el-tag type="success"  style="margin-right:20px;">已认证</el-tag>
        <span  style="margin-right:10px;">{{ this.user.realName }}</span>
        <span>{{ this.user.idNumber }}</span>
      </div>
      <div v-if="this.user.isReal == false" class="cursor flex">
        <el-tag type="danger">未认证</el-tag>
        <div class="goCertify" @click="onSure">
          <el-tag type="danger">点击认证</el-tag>
        </div>
      </div>
     </div>
    </el-card>
    <div class="modal"  v-if="showCertification">
        <div class="modal-dialog">
          <div class="modal-header">
            <span>实名认证</span>
            <a
              href="javascript:;"
              class="icon-close"
              v-on:click="clearInput"
            ></a>
          </div>
          <div class="modal-body">
            <div class="certificationInput">
              <span>您的姓名</span>
              <el-input
                v-model="mgyUserVerifyParam.realUserName"
                placeholder="您的姓名"
              ></el-input>
            </div>
            <div class="certificationInput">
              <span>您的身份证号</span>
              <el-input
                v-model="mgyUserVerifyParam.idNumber"
                placeholder="您的身份证号"
              ></el-input>
            </div>
          </div>
          <div class="modal-footer">
            <div class="btn-group">
              <a href="javascript:;" class="btn" v-on:click="getCertification"
                >确认</a
              >
              <a
                href="javascript:;"
                class="btn btn-default"
                v-on:click="clearInput"
                >取消</a
              >
            </div>
          </div>
        </div>
      </div>
       <scan-pay-code
        title="实名认证"
        scan="支付宝"
        v-if="showPay"
        @close="cancel"
        @submit="completeCetification"
        @cancel="cancel"
        :img="payImg"
      ></scan-pay-code>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex";
import QRCode from "qrcode";
// @ is an alias to /src
import ScanPayCode from "./../components/scanPayCode";
import Modal from "./../components/Modal.vue";
export default {
  components: { Modal, ScanPayCode },
  name: "realName",
  mounted() {
  },
  data() {
    return {
      showTitle:true,
      showCertification: false,
      mgyUserVerifyParam: {
        realUserName: "",
        idNumber: "",
      },
      payImg: "", //微信支付的二维码地址
      showPay: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    ...mapActions(["updateUser"]),
    certification() {
      this.$http
        .post("/mgyUser/verifyUser", this.mgyUserVerifyParam)
        .then((res) => {
          if (res.data.code == 200) {
            const certify = JSON.parse(res.data.data);
            console.log(certify)
            if (certify.Code !== 200) {
              this.$message.error(certify.Message);
                this.showTitle = true
            } else {
              this.certifyUrl = certify.Data.certifyUrl;
              this.certifyId = certify.Data.certifyId;
              this.getCode();
            }
          }else{
             this.$message.error(res.data.data);
             this.showTitle = true
          }
        });
    },
    getCertification() {
      this.certification();
      this.showCertification = false;
    },
    completeCetification() {
      this.showPay = false;
      this.showTitle = true
      this.$http
        .get(`/mgyUser/verifyUserCallBack?certifyId=${this.certifyId}`)
        .then((res) => {
         
          console.log(res);
          if (res.data.code == 200) {
            if (res.data.data) {
              this.$message.success("您已完成实名认证，去购买商品吧");
              this.updateUser();
            } else {
              this.$message.error("实名认证失败");
            }
          } else {
            this.$message.error("实名认证失败");
          }
        });
    },
    onSure() {
      this.showTitle = false
      this.showCertification = true;
    },
    clearInput(){
      this.showTitle = true
      this.showCertification = false;
      this.mgyUserVerifyParam.realUserName = ''
      this.mgyUserVerifyParam.idNumber = ''
    },
     getCode() {
        QRCode.toDataURL(this.certifyUrl).then((url) => {
        this.showPay = true;
        this.payImg = url;
      });
    },
    cancel(){
       this.showTitle = true
      this.showPay = false
    }
  },
};
</script>
<style lang="scss">
.cursor {
  cursor: pointer;
  .goCertify {
    margin-left: 20px;
  }
}
@import "./../assets/scss/config.scss";
@import "./../assets/scss/button.scss";
@import "./../assets/scss/mixin.scss";
@import "./../assets/scss/modal.scss";
</style>

