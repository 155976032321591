<template>
  <div>
    <nav-header></nav-header>
    <div class="info-banner"></div>
<!--    <div class="tabs-container">-->
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick" align="center">
        <el-tab-pane name="first">
          <template v-slot:label>
            <div class="tab-header" id="tab1" :style="{ 'background-image': 'url(' + imageUrl1 + ')' }"></div>
          </template>
          <div>
            <div class="pptpArea">
              <div class="pptpArea-box">
                <el-radio-group v-model="radio1" class="area" :click="chooseArea()" size="medium" border="true">
                  <el-radio-button v-for="item in pptpList" :key="item.name" :label="item.label" class="radio">{{
                      item.label
                    }}
                  </el-radio-button>
                  <el-radio-button class="radio" style="border: 0;" label="混播">全国混播</el-radio-button>
                </el-radio-group>
                <div class="cityBox">
                  <el-radio-group class="city-box" v-model="radio2">
                    <el-radio v-for="item in cityList" :key="item.value" @change="chooseCity(item)" :label="item.value"
                              class="city">{{ item.label }}
                    </el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="order-box">
                <p class="title">{{ cityName }}  动态拨号VPS</p>
                <div class="table">
                  <el-collapse>
                    <el-collapse-item :title="item.productTitle[index]" :name="index" v-for="(item, index) in productList"
                                      :key="item.id">
                      <template slot="title" class="block">
                        <div class="block-title">
                          <p class="p1">{{ item.productTitle }}</p>
                          &nbsp;<p class="p2">{{ cityName }}</p>
                          <!--                  <p class="p2">{{ cityName }} {{ operator }}</p>-->
                        </div>
                        <div class="info">
                          <p class="p3">带宽：</p>
                          <p class="p4">{{ item.productBandwidth }}</p>
                        </div>
                        <div class="info">
                          <p class="p3">硬盘：</p>
                          <p class="p4">{{ item.productSsd }}G</p>
                        </div>
                        <div class="info">
                          <p class="p3">系统：</p>
                          <el-select v-model="mirrorValue[index]" placeholder="请选择" value-key="id">
                            <el-option
                                v-for="itemMirror in item.mirrorList"
                                :key="itemMirror.id"
                                :label="itemMirror.mirrorName"
                                :value="itemMirror">
                            </el-option>
                          </el-select>
                        </div>
                        <div class="info">
                          <p class="p3">时间：</p>
                          <el-select v-model="timeValue[index]" @change="selectTime(timeValue[index], index)" placeholder="请选择"
                                     value-key='id'>
                            <el-option
                                v-for="itemTime in item.productSkuList"
                                :key="itemTime.id"
                                :label="itemTime.showTime"
                                :value="itemTime">
                            </el-option>
                          </el-select>
                        </div>
                        <div class="price">
                          <p class="price-text"><span>{{ item.showAmount }}</span> 元</p>
                        </div>
                        <el-button class="pay">选 购</el-button>
                      </template>
                      <div class="set-box">
                        <div class="pass-box">
                          <p class="pass-text">服务器密码：</p>
                          <el-input v-model="password[index]"/>
                        </div>
                        <div class="number-box">
                          <p class="number-text">开通数量：</p>
                          <el-input v-model="number[index]"/>
                        </div>
                        <div class="pay-type-box">
                          <p class="pay-type-text">支付方式：</p>
                          <el-select v-model="payTypeValue[index]" placeholder="请选择" value-key="value">
                            <el-option
                                v-for="itemPay in payTypeOptions"
                                :key="itemPay.value"
                                :label="itemPay.label"
                                :value="itemPay">
                            </el-option>
                          </el-select>
                        </div>
                        <div class="btn-box">
                          <!-- <el-button class="payBtnFree">免费试用</el-button> -->
                          <el-button class="payBtnBuy" @click="confirmOrder(item, index)">立即开通</el-button>
                        </div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </div>
            </div>
            <div class="confirm-order-box">
              <el-dialog
                  title="确定订单"
                  :visible.sync="dialogVisible"
                  v-loading="loading"
                  width="52%">
                <div class="product">
                  <div class="product-title">
                    <p class="p1">{{ product.productTitle }}</p>
                    <p class="p2">提供海量IP区域选择，保障不同地区，不同网络用户快速访问</p>
                  </div>
                  <div class="product-infos">
                    <p class="p3">硬盘</p>
                    <p class="p4">{{ product.productSsd }}G</p>
                  </div>
                  <div class="product-infos">
                    <p class="p3">CPU</p>
                    <p class="p4">{{ product.productCpu }}</p>
                  </div>
                  <div class="product-infos">
                    <p class="p3">月流量</p>
                    <p class="p4">不限</p>
                  </div>
                  <div class="product-infos">
                    <p class="p3">内存</p>
                    <p class="p4">{{ product.productMemory }}G</p>
                  </div>
                  <div class="product-infos">
                    <p class="p3">带宽</p>
                    <p class="p4">{{ product.productBandwidth }}</p>
                  </div>
                </div>
                <div class="order">
                  <div class="order-info">
                    <p>选择镜像：<span>{{ product.mirrorName }}</span></p>
                  </div>
                  <div class="order-info">
                    <p>购买时长：<span>{{ product.showTime }}</span></p>
                  </div>
                  <div class="order-info">
                    <p>用户名：<span>Administrator</span></p>
                  </div>
                  <div class="order-info">
                    <p>登录密码：<span>{{ orderParam.vpsLoginPassword }}</span></p>
                  </div>
                  <div class="order-info">
                    <p>购买数量：<span>{{ orderParam.count }}台</span></p>
                  </div>
                  <div class="order-info">
                    <p>价格：<span style="color:#f60;">￥{{ product.amount }}元</span></p>
                  </div>
                  <div class="order-info">
                    <p>拨号类型：<span style="font-weight:800">{{ product.pptpType }}</span></p>
                  </div>
                  <div class="order-info-pay">
                    <p>支付类型：
                      <el-button class="pay-type" style="background-color:#f60;color:#FFFFFF;width:140px;border:0;">
                        {{ product.payType }}
                      </el-button>
                    </p>
                  </div>
                </div>
                <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" :disabled="orderBtn" @click="pay()">确 定</el-button>
        </span>
              </el-dialog>
            </div>
            <!-- 微信支付 -->
            <weixin-pay-code
                title="微信支付"
                scan="微信支付"
                v-if="showWeixinPay"
                @close="showWeixinPay = false"
                @submit="wxPaySearch"
                @cancel="showWeixinPay = false"
                :img="code_url"
            ></weixin-pay-code>
            <el-dialog
                title="支付确认"
                :visible.sync="showAliPay"
                width="30%">
              <span>请确认是否完成支付?</span>
              <span slot="footer" class="dialog-footer">
        <el-button @click="showAliPay = false">取 消</el-button>
        <el-button type="primary" @click="aliPaySearch">确 定</el-button>
      </span>
            </el-dialog>
            <el-dialog
                title="实名认证"
                :visible.sync="certRealFlag"
                width="30%">
              <span>请前往控制台完成实名认证</span>
              <span slot="footer" class="dialog-footer">
        <el-button @click="certRealFlag = false">取 消</el-button>
        <el-button type="primary" @click="showCertification">确 定</el-button>
      </span>
            </el-dialog>
          </div>
        </el-tab-pane>
        <el-tab-pane name="second">
          <template v-slot:label>
            <div class="tab-header" id="tab2" :style="{ 'background-image': 'url(' + imageUrl2 + ')' }"></div>
          </template>
          <div>
            <div class="pptpArea">
              <div class="pptpArea-box">
                <el-radio-group v-model="radio1" class="area" :click="chooseArea()" size="medium" border="true">
                  <el-radio-button v-for="item in pptpList" :key="item.name" :label="item.label" class="radio">{{
                      item.label
                    }}
                  </el-radio-button>
<!--                  <el-radio-button class="radio" style="border: 0;" label="混播">全国混播</el-radio-button>-->
                </el-radio-group>
                <div class="cityBox">
                  <el-radio-group class="city-box" v-model="radio2">
                    <el-radio v-for="item in cityList" :key="item.value" @change="chooseCity(item)" :label="item.value"
                              class="city">{{ item.label }}
                    </el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="order-box">
                <p class="title">{{ cityName }}  动态拨号VPS</p>
                <div class="table">
                  <el-collapse>
                    <el-collapse-item :title="item.productTitle[index]" :name="index" v-for="(item, index) in productList"
                                      :key="item.id">
                      <template slot="title" class="block">
                        <div class="block-title">
                          <p class="p1">{{ item.productTitle }}</p>
                          &nbsp;<p class="p2">{{ cityName }}</p>
                          <!--                  <p class="p2">{{ cityName }} {{ operator }}</p>-->
                        </div>
                        <div class="info">
                          <p class="p3">带宽：</p>
                          <p class="p4">{{ item.productBandwidthJdy }}</p>
                        </div>
                        <div class="info">
                          <p class="p3">硬盘：</p>
                          <p class="p4">{{ item.productSsd }}G</p>
                        </div>
                        <div class="info">
                          <p class="p3">系统：</p>
                          <el-select v-model="mirrorValue[index]" placeholder="请选择" value-key="id">
                            <el-option
                                v-for="itemMirror in item.mirrorList"
                                :key="itemMirror.id"
                                :label="itemMirror.mirrorName"
                                :value="itemMirror">
                            </el-option>
                          </el-select>
                        </div>
                        <div class="info">
                          <p class="p3">时间：</p>
                          <el-select v-model="timeValue[index]" @change="selectTime1(timeValue[index], index)" placeholder="请选择"
                                     value-key='id'>
                            <el-option
                                v-for="itemTime in item.productSkuList"
                                :key="itemTime.id"
                                :label="itemTime.showTime"
                                :value="itemTime">
                            </el-option>
                          </el-select>
                        </div>
                        <div class="price">
                          <p class="price-text"><span>{{ item.showAmount }}</span> 元</p>
                        </div>
                        <el-button class="pay">选 购</el-button>
                      </template>
                      <div class="set-box">
                        <div class="pass-box">
                          <p class="pass-text">服务器密码：</p>
                          <el-input v-model="password[index]"/>
                        </div>
                        <div class="number-box">
                          <p class="number-text">开通数量：</p>
                          <el-input v-model="number[index]"/>
                        </div>
                        <div class="pay-type-box">
                          <p class="pay-type-text">支付方式：</p>
                          <el-select v-model="payTypeValue[index]" placeholder="请选择" value-key="value">
                            <el-option
                                v-for="itemPay in payTypeOptions"
                                :key="itemPay.value"
                                :label="itemPay.label"
                                :value="itemPay">
                            </el-option>
                          </el-select>
                        </div>
                        <div class="btn-box">
                          <!-- <el-button class="payBtnFree">免费试用</el-button> -->
                          <el-button class="payBtnBuy" @click="confirmOrder(item, index)">立即开通</el-button>
                        </div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </div>
            </div>
            <div class="confirm-order-box">
              <el-dialog
                  title="确定订单"
                  :visible.sync="dialogVisibleJdy"
                  v-loading="loading"
                  width="52%">
                <div class="product">
                  <div class="product-title">
                    <p class="p1">{{ product.productTitle }}</p>
                    <p class="p2">提供海量IP区域选择，保障不同地区，不同网络用户快速访问</p>
                  </div>
                  <div class="product-infos">
                    <p class="p3">硬盘</p>
                    <p class="p4">{{ product.productSsd }}G</p>
                  </div>
                  <div class="product-infos">
                    <p class="p3">CPU</p>
                    <p class="p4">{{ product.productCpu }}</p>
                  </div>
                  <div class="product-infos">
                    <p class="p3">月流量</p>
                    <p class="p4">不限</p>
                  </div>
                  <div class="product-infos">
                    <p class="p3">内存</p>
                    <p class="p4">{{ product.productMemory }}G</p>
                  </div>
                  <div class="product-infos">
                    <p class="p3">带宽</p>
                    <p class="p4">{{ product.productBandwidthJdy }}</p>
                  </div>
                </div>
                <div class="order">
                  <div class="order-info">
                    <p>选择镜像：<span>{{ product.mirrorName }}</span></p>
                  </div>
                  <div class="order-info">
                    <p>购买时长：<span>{{ product.showTime }}</span></p>
                  </div>
                  <div class="order-info">
                    <p>用户名：<span>Administrator</span></p>
                  </div>
                  <div class="order-info">
                    <p>登录密码：<span>{{ orderParam.vpsLoginPassword }}</span></p>
                  </div>
                  <div class="order-info">
                    <p>购买数量：<span>{{ orderParam.count }}台</span></p>
                  </div>
                  <div class="order-info">
                    <p>价格：<span style="color:#f60;">￥{{ product.amount }}元</span></p>
                  </div>
                  <div class="order-info">
                    <p>拨号类型：<span style="font-weight:800">{{ product.pptpType }}</span></p>
                  </div>
                  <div class="order-info-pay">
                    <p>支付类型：
                      <el-button class="pay-type" style="background-color:#f60;color:#FFFFFF;width:140px;border:0;">
                        {{ product.payType }}
                      </el-button>
                    </p>
                  </div>
                </div>
                <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleJdy = false">取 消</el-button>
          <el-button type="primary" :disabled="orderBtn" @click="pay()">确 定</el-button>
        </span>
              </el-dialog>
            </div>
            <!-- 微信支付 -->
            <weixin-pay-code
                title="微信支付"
                scan="微信支付"
                v-if="showWeixinPay"
                @close="showWeixinPay = false"
                @submit="wxPaySearch"
                @cancel="showWeixinPay = false"
                :img="code_url"
            ></weixin-pay-code>
            <el-dialog
                title="支付确认"
                :visible.sync="showAliPay"
                width="30%">
              <span>请确认是否完成支付?</span>
              <span slot="footer" class="dialog-footer">
        <el-button @click="showAliPay = false">取 消</el-button>
        <el-button type="primary" @click="aliPaySearch">确 定</el-button>
      </span>
            </el-dialog>
            <el-dialog
                title="实名认证"
                :visible.sync="certRealFlag"
                width="30%">
              <span>请前往控制台完成实名认证</span>
              <span slot="footer" class="dialog-footer">
        <el-button @click="certRealFlag = false">取 消</el-button>
        <el-button type="primary" @click="showCertification">确 定</el-button>
      </span>
            </el-dialog>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavHeader from "./../components/nav-header.vue";
import {mapState, mapActions} from "vuex";
import QRCode from "qrcode";
import WeixinPayCode from "./../components/weixinPayCode";
import Loading from "./../components/loading.vue";
import ScanPayCode from "./../components/scanPayCode";
import Modal from "./../components/Modal.vue";

export default {
  name: "host",
  components: {NavHeader, Loading, Modal, ScanPayCode, WeixinPayCode},
  data() {
    return {
      mgyProductPageParam: {
        pageIndex: 1,
        pageSize: 100,
        keyword: "指定区域",
      },
      isShow: true,
      orderBtn: false,
      productList: [],
      pptpList: null,
      activeName: 'first',
      imageUrl1:"https://blbw.oss-cn-shanghai.aliyuncs.com/bs.png",
      imageUrl2:"https://blbw.oss-cn-shanghai.aliyuncs.com/p.png",
      showWeixinPay: false,
      showAliPay: false,
      certRealFlag: false,
      code_url: null,
      cityList: null,
      orderNo: null,
      radio1: "",
      radio2: "",
      cityName: null,
      operator: null,
      password: [],
      loading: false,
      dialogVisible: false,
      dialogVisibleJdy: false,
      product: {
        productTitle: null,
        showTime: null,
        mirrorName: null,
        amount: null,
        payType: null,
      },
      number: [1, 1, 1, 1, 1],
      mirrorValue: [],
      timeValue: [],
      orderParam: {
        productId: null,
        versionType: null,
        vpsLoginPassword: null,
        skuId: null,
        count: null,
        mirrorId: null,
        payType: null,
        pptpArea: null,
        pptpHost: null
      },
      allCityList: [{
        label: "全国混播",
        value: "全国混播"
      }],
      payTypeOptions: [{
        value: '0',
        label: '余额支付'
      }, {
        value: '1',
        label: '支付宝'
      }, {
        value: '2',
        label: '微信'
      }],
      payTypeValue: [{
        value: '0',
        label: '余额支付'
      }, {
        value: '0',
        label: '余额支付'
      }, {
        value: '0',
        label: '余额支付'
      }, {
        value: '0',
        label: '余额支付'
      }, {
        value: '0',
        label: '余额支付'
      }],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created() {
    this.getProduct();
    this.getPptpArea();
  },
  methods: {
    ...mapActions(["updateUser"]),
    selectTime(item, index) {
      console.log(item);
      console.log(index);
      this.productList[index].showAmount = item.price;
      console.log(this.productList[index]);
    },
    selectTime1(item, index) {
      console.log(item);
      console.log(index);
      this.productList[index].showAmount = item.jdyPrice;
      console.log(this.productList[index]);
    },
    sureWeixinPay() {
      console.log("微信支付");
    },
    handleClick(tab, event) {
      this.getProduct();
      this.getPptpArea();
      if (tab.name === 'first'){
        this.orderParam.versionType = 1
        this.imageUrl1="https://blbw.oss-cn-shanghai.aliyuncs.com/bs.png"
        this.imageUrl2="https://blbw.oss-cn-shanghai.aliyuncs.com/p.png"
      }
    if (tab.name === 'second'){
        this.orderParam.versionType = 2
        this.imageUrl1="https://blbw.oss-cn-shanghai.aliyuncs.com/b.png"
        this.imageUrl2="https://blbw.oss-cn-shanghai.aliyuncs.com/ps.png"
      }
    },
    pay() {
      this.loading = true;
      if (this.orderParam.payType == 0) {
        this.balancePay();
      }
      if (this.orderParam.payType == 1) {
        this.aliPay();
      }
      if (this.orderParam.payType == 2) {
        this.wxPay();
      }
    },
    aliPay() {
      this.$http.post("/mgyOrder/generateOrder", this.orderParam).then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
          var alipayInfo = res.data.data;
          this.orderNo = alipayInfo.outTradeNo;
          let routeData = this.$router.resolve({
            name: "alipay",
            query: {content: alipayInfo.body},
          });
          window.open(routeData.href, "_blank");
          this.showAliPay = true;
        } else {
          this.$message.error(res.data.data);
        }
      }).catch((err) => {

      });
    },
    wxPay() {
      this.$http.post("/mgyOrder/generateOrder", this.orderParam).then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
          this.orderNo = res.data.data.orderNo;
          this.getCode(res.data.data.code_url);
        } else {
          this.$message.error(res.data.data);
        }
        if (this.activeName === 'first') {
          this.dialogVisible = false;
        }
        if (this.activeName === 'second') {
          this.dialogVisibleJdy = false;
        }
      }).catch((err) => {

      });
    },
    balancePay() {
      this.$http.post("/mgyOrder/generateOrder", this.orderParam).then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
          if (res.data.data == false) {
            this.$message.error("账户余额不足");
          } else if (res.data.data == "当前无网络资源可用") {
            this.$message.error("当前无网络资源可用");
          } else if (res.data.data == 5) {
            this.$message.error("仅限新用户下单")
          } else if (res.data.data == 7) {
            this.$message.error("试用产品每次仅可开通一台")
          } else if (res.data.data == 8) {
            this.$message.error("当天限购20次")
          } else if (res.data.data == 9) {
            this.$message.error("试用机器只能试用余额支付")
          } else {
            this.orderBtn = true;
            this.$message.success("支付成功");
            this.$router.push("/myProduct");
            this.updateUser();
          }
        }
      }).catch((err) => {

      });
    },
    //阿里支付回调
    aliPaySearch() {
      this.showAliPay = false;
      this.$http.post('/payBack/aliPayCallBack/' + this.orderNo).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("支付成功");
          this.$router.push("/myProduct");
          this.updateUser();
        } else {
          this.$message.error("支付失败!");
        }
      });
    },
    //微信支付回调
    wxPaySearch() {
      this.showWeixinPay = false;
      this.$http.get('/payBack/wxPaySearchOrder/' + this.orderNo).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.$router.push({name: "myProduct"});
          this.updateUser();
        } else {
          this.$message.error("支付失败!");
        }
      });
    },
    getCode(e) {
      QRCode.toDataURL(e).then((url) => {
        this.showWeixinPay = true;
        this.code_url = url;
      });
    },
    confirmOrder(item, index) {
      if (this.radio1 !== "混播") {
        if (this.cityName == null) {
          return this.$message.error("请选择具体城市！");
        }
      }
      if (this.radio1 === '混播') {
        if (this.mgyProductPageParam.keyword !== "混播") {
          return this.$message.error("请选择具体城市！");
        }
      }
      let token = window.localStorage.getItem("token");
      if (!token) {
        window.localStorage.clear();
        return this.$router.push("/login");
      }
      if (this.user.isReal == false) {
        return this.certRealFlag = true;
      }
      //验证输入框非空内容
      if (this.radio2 == "") {
        return this.$message.error("请选择城市！");
      }
      if (this.mirrorValue[index] == null) {
        return this.$message.error("请选择系统！");
      }
      if (this.timeValue[index] == null) {
        return this.$message.error("请选择时间！");
      }
      if (this.password[index] == null) {
        return this.$message.error("请输入密码");
      }
      if (this.number[index] == null) {
        return this.$message.error("请输入开通数量");
      }
      var reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,20}$/;
      if (!this.password[index].match(reg)) {
        return this.$message.error("密码为大小写字母、数字、特殊符号的组合，至少包含三种，长度 8-20 位");
      }
      this.product = item;
      this.product.mirrorName = this.mirrorValue[index].mirrorName;
      this.product.showTime = this.timeValue[index].showTime;
      if (this.activeName === 'first') {
        this.product.amount = this.timeValue[index].price * this.number[index];
      }
      if (this.activeName === 'second') {
        this.product.amount = this.timeValue[index].jdyPrice * this.number[index];
      }


      this.product.payType = this.payTypeValue[index].label;
      if (this.activeName === 'first') {
        this.dialogVisible = true;
      }
      if (this.activeName === 'second') {
        this.dialogVisibleJdy = true;
      }

      this.orderParam.skuId = this.timeValue[index].id;
      this.orderParam.count = this.number[index];
      this.orderParam.mirrorId = this.mirrorValue[index].id;
      this.orderParam.vpsLoginPassword = this.password[index];
      this.orderParam.payType = this.payTypeValue[index].value;
      this.orderParam.productId = item.id;
    },
    showCertification() {
      this.$router.push("/realName");
    },
    chooseCity(item) {
      this.timeValue = [];
      if (this.radio2 != "全国混播") {
        this.orderParam.pptpArea = item.label;
        this.orderParam.pptpHost = this.radio2;
      }
      this.cityName = item.label;
      // if (this.cityName != null) {
      //   this.cityName = this.cityName;
      // }
      this.getProduct();
    },
    chooseArea() {
      if (this.radio1 != "混播") {
        this.pptpList.forEach(element => {
          if (element.label == this.radio1) {
            this.cityList = element.children;
          }
        });
      } else {
        this.cityName = null;
        // this.operator = null;
        this.cityList = this.allCityList;
      }
    },
    getPptpArea() {
      if (this.activeName === 'first'){
        this.$http.get("/mgyPptpServer/getPptpServerList").then((res) => {
          if (res.data.code == 200) {
            this.pptpList = res.data.data;
            this.radio1 = this.pptpList[0].label
            this.pptpList.forEach(element => {
              if (element.label == this.radio1) {
                this.cityList = element.children;
              }
            });
            this.radio2 = this.pptpList[0].children[0].label

            this.orderParam.pptpArea = item.label;
            this.orderParam.pptpHost = this.radio2;
            this.cityName = item.label;
            // this.operator = this.cityName.substring(this.cityName.length -2, item.label.length);
            if (this.cityName != null) {
              this.cityName = this.cityName + " - ";
            }
            // this.getProduct();
          }
        });
      }
      if (this.activeName === 'second'){
        this.$http.get("/mgyPptpServer/getPptpServerListNew").then((res) => {
          if (res.data.code == 200) {
            this.pptpList = res.data.data;
            this.radio1 = this.pptpList[0].label
            this.pptpList.forEach(element => {
              if (element.label == this.radio1) {
                this.cityList = element.children;
              }
            });
            this.radio2 = this.pptpList[0].children[0].label

            this.orderParam.pptpArea = item.label;
            this.orderParam.pptpHost = this.radio2;
            this.cityName = item.label;
            // this.operator = this.cityName.substring(this.cityName.length -2, item.label.length);
            if (this.cityName != null) {
              this.cityName = this.cityName + " - ";
            }
            // this.getProduct();
          }
        });
      }
    },
    getProduct() {
      if (this.activeName === 'first'){
        if (this.radio1 == "混播") {
          this.mgyProductPageParam.keyword = "混播";
        } else {
          this.mgyProductPageParam.keyword = "指定区域";
        }
        this.$http
            .post("/mgyProduct/getPageList2", this.mgyProductPageParam)
            .then((res) => {
              this.productList = res.data.data.records;
              let user = JSON.parse(localStorage.getItem("user"))
              if (user && !user['isNew'] && !user['isAgent']) {
                this.productList = this.productList.splice(1, 50);
              }
            });
      }
      if (this.activeName === 'second'){
        if (this.radio1 == "混播") {
          this.mgyProductPageParam.keyword = "混播";
        } else {
          this.mgyProductPageParam.keyword = "指定区域";
        }
        this.$http
            .post("/mgyProduct/getPageList3", this.mgyProductPageParam)
            .then((res) => {
              this.productList = res.data.data.records;
              let user = JSON.parse(localStorage.getItem("user"))
              if (user && !user['isNew'] && !user['isAgent']) {
                this.productList = this.productList.splice(1, 50);
              }
            });
      }
    },
  },
};
</script>
<style>
::v-deep .el-tabs__nav-scroll{
  width:50%;
  margin:0 auto
}

.el-tabs {
  background-color: #FFFFFF;
}
.el-tabs__header {
  height: 150px; 
  width: 1240px;
  text-align: center; 

}
.el-tabs__item {
  margin-left: 0px;
}
.el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav{
  height: 150px; 
}

.tab-header {
  width: 600px;
  height: 150px;
  background-size: cover; 
  background-position: center; 
  margin: 0px;
}

.pptpArea {
  width: 100%;
  background-color: #FFFFFF;
}
.pptpArea-box {
  width: 65%;
  margin: 0 auto;
}

.pptpArea-box .el-radio-button__inner {
  font-size: 16px;
}

.pptpArea-box .area {
  margin-left: -5px;
  margin-top: 10px;
}

.pptpArea-box .area .radio {
  margin-top: 10px;
  font-size: 20px !important;
}

.pptpArea-box .cityBox {
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
}

.pptpArea-box .city-box {
  width: 100%;
}

.pptpArea-box .city-box .city {
  width: 220px;
  margin-bottom: 20px;
}

.box {
  width: 100%;
  /* height: 800px; */
  background-color: #FFFFFF;
  padding-bottom: 120px;
}

.order-box {
  width: 68%;
  margin: 0 auto;
}

.order-box .title {
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  color: #3E4D5C;
}

.order-box .table {
  margin-top: 40px;
  border-left: 3px solid #3F6FF6;
}

.order-box .table .block {
  display: flex;
}

.order-box .table .el-collapse-item__header {
  padding: 10px 22px;
  height: 130px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 6px !important;
  color: #3E4D5C;
  line-height: 1.6;
}

.order-box .table .block-title {
  width: 250px;
  line-height: 22px;
  height: 80px;
  border-right: 1px solid #E6E9EF;
  padding-right: 0px;
  margin-left: 7px;
}

.order-box .table .block-title .p1 {
  font-weight: 800;
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 14px;
}

.order-box .table .block-title .p2 {
  font-size: 16px;
}

.order-box .table .info {
  margin-left: 50px;
  line-height: 30px;
}

.order-box .table .p3 {
  font-size: 16px;
}

.order-box .table .p4 {
  font-size: 16px;
  font-weight: 800;
}

.order-box .table .el-input__inner {
  width: 180px;
  height: 30px;
}

.order-box .table .el-input__icon {
  line-height: 30px !important;
}

.order-box .table .el-collapse-item__arrow {
  display: none;
}

.order-box .table .price {
  margin-left: 100px;
  height: 80px;
  line-height: 80px;
  border-left: 1px solid #E6E9EF;
  color: #3E4D5C;
  padding-left: 25px;
}

.order-box .table .price .price-text {
  font-size: 14px;
}

.order-box .table .price .price-text span {
  font-weight: 800;
  font-size: 26px;
  color: #f56f46;
}

.order-box .table .pay {
  margin-left: auto;
  height: 132px;
  width: 100px;
  margin-right: -22px;
  border-radius: 0;
  margin-top: -1px;
  background-color: #4F7BF8;
  color: #FFFFFF;
  font-size: 18px;
}

.order-box .table .el-collapse-item__content {
  height: 130px;
  background-color: #F9FAFF;
}

.order-box .table .set-box {
  height: 130px;
  display: flex;
}

.order-box .table .pass-box {
  width: 210px;
  margin-top: 28px;
  margin-left: 27px;
  line-height: 35px;
  color: #3E4D5C;
}

.order-box .table .number-box {
  width: 210px;
  margin-top: 28px;
  margin-left: 27px;
  line-height: 35px;
  color: #3E4D5C;
}

.order-box .table .pay-type-box {
  width: 210px;
  margin-top: 28px;
  margin-left: 27px;
  line-height: 35px;
  color: #3E4D5C;
}

.order-box .table .btn-box {
  margin-left: auto;
  line-height: 130px;
  margin-right: 50px;
}

.order-box .table .btn-box .payBtnFree {
  color: #3f6ff6;
  border-radius: 4px;
  border: 1px solid #3f6ff6;
}

.order-box .table .btn-box .payBtnBuy {
  /* background-image: linear-gradient(90deg, #ffbc76 0%, #ffbc76 100%), linear-gradient( #ffffff, #ffffff); */
  background-color: #FFCC84;
  background-blend-mode: normal, normal;
  border: 0;
  border-radius: 4px;
  color: #513a21;
  transition: .25s ease-in-out;
}

.order-box .table .btn-box .payBtnBuy:hover {
  background-color: #FFBC7B;
}

.confirm-order-box .product {
  border: 1px solid #BBBBBB;
  height: 120px;
  display: flex;
}

.confirm-order-box .product .product-title {
  width: 270px;
  margin-top: 24px;
  margin-left: 20px;
  margin-right: 50px;
}

.confirm-order-box .product .product-title .p1 {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
}

.confirm-order-box .product .product-title .p2 {
  font-size: 13px;
  margin-top: 5px;
}

.confirm-order-box .product .product-infos {
  width: 75px;
  margin-top: 28px;
  margin-right: 52px;
}

.confirm-order-box .product .product-infos .p3 {
  font-size: 13px;
  color: #777777;
}

.confirm-order-box .product .product-infos .p4 {
  font-size: 16px;
  color: #333333;
  margin-top: 8px;
}

.confirm-order-box .order {
  border: 1px solid #BBBBBB;
  height: 390px;
  margin-top: 20px;
}

.confirm-order-box .order .order-info {
  margin-top: 20px;
  margin-left: 20px;
}

.confirm-order-box .order .order-info span {
  font-size: 16px;
}

.confirm-order-box .order .order-info-pay {
  margin-top: 20px;
  margin-left: 20px;
}

.confirm-order-box .order .order-info-pay .pay-type {
  font-size: 14px;
  letter-spacing: 1px;
}
</style>
