<template>
  <div class="bannerCss">
    <!-- banner begin -->
    <nav-header></nav-header>
    <img class="act-img" :src="backImg" @click="goHost"/>
  </div>
</template>
<script>
import { mapState } from "vuex";
import NavHeader from "./../components/nav-header.vue";
export default {
  name: "banner",
  components: { NavHeader },
  data() {
    return {
      activityImg:'',
      mgyBannerPageParam: {
        pageIndex: 1,
        pageSize: 10,
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    backImg(){
      if(this.activityImg){
        return this.activityImg
      }
    }
  },
  created() {
    this.getActivity()
  },
  methods: {
    getActivity(){
      this.$http.get('/mgySystemSetting/getActivityUrl').then(res => {
        console.log(res)
        this.activityImg = res.data.data.systemValue
      })
    },
    goHost(){
      this.$router.push('/host')
    }
  },
};
</script>
<style lang="scss" scoped>
.bannerCss{
  .act-img {
    width: 100% !important;
	position: relative;
	text-align: center;
		height: 500px;
  .main-title
{
   top:325px;
  font-size:40px;
	color: #fff;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}
.main-title-desc{
   top:225px;
  font-size:26px;
	color: #fff;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}

}
.swiper-container {
  height: 500px;
  .swiper-button-prev {
    left: 200px;
  }
  .swiper-button-next {
    right: 200px;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .swiper-cube-shadow {
    opacity: 1;
  }
}

.imgTitle{
  width:100%;
  height:100%;
}
.act-banner-img {
	position: relative;
	text-align: center;
	height: 500px;
}
}

</style>