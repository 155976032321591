<template>
  <div class="index">
    <nav-header></nav-header>
    
  <div class="bannerCss">
    <!-- banner begin -->
    <div class="act-banner-img">
      <swiper v-bind:options="swiperOption">
        <swiper-slide v-for="(item, index) in slideList" v-bind:key="index" @click="goHost">
          <img v-bind:src="item.bannerPic"/>
        </swiper-slide>
        <!-- Optional controls -->
      <div class="swiper-pagination" slot="pagination"></div>
       <!--   <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
    </div>
  </div>
    <!--banner end-->

    <!--快捷入口-->

    <!--快捷入口 end-->

    <!--产品-->
    <div class="index-product">
      <div class="container">
        <div class="module-title">
          蘑菇云体验站为您提供<span>高速、稳定、安全、弹性</span>的云计算服务
          <p>计算、存储、监控、安全，完善的云产品满足您的一切所需</p>
        </div>
        <div class="new-product-intro">
          <div class="intro-calc">
            <div class="intro-title">
              <i class="title-icon"></i>
              <span>云计算服务</span>
            </div>
            <ul class="intro-list">
              <li class="private">
                <a href="javascript:;">
                  <i class="p-icon icon iconfont">&#xe6cb;</i>
                  <div class="intro-info">
                    <div class="main-title private">云服务器</div>
                    <div class="subtitle">极速稳定高弹性的计算服务</div>
                  </div>
                </a>
              </li>
              <li class="private">
                <a href="javascript:;">
                  <i class="p-icon icon iconfont load-icon">&#xe6c7;</i>
                  <div class="intro-info">
                    <div class="main-title private">负载均衡</div>
                    <div class="subtitle">高性能流量分发的负载均衡服务</div>
                  </div>
                </a>
              </li>
              <li class="private">
                <a href="javascript:;">
                  <i class="p-icon icon iconfont mysql-icon">&#xe6d1;</i>
                  <div class="intro-info">
                    <div class="main-title private">云数据库MySQL</div>
                    <div class="subtitle">稳定可靠、可弹性伸缩的数据方案</div>
                  </div>
                </a>
              </li>
              <li class="private">
                <a href="javascript:;">
                  <i class="p-icon icon iconfont sqlserver-icon">&#xe6d0;</i>
                  <div class="intro-info">
                    <div class="main-title private">云数据库 SQL Server</div>
                    <div class="subtitle">安全运行、轻松管理的数据方案</div>
                  </div>
                </a>
              </li>
              <li class="private">
                <a href="javascript:;">
                  <i class="p-icon icon iconfont">&#xe6c6;</i>
                  <div class="intro-info">
                    <div class="main-title private">对象存储</div>
                    <div class="subtitle">高可用、易用、低成本、一站式</div>
                  </div>
                </a>
              </li>
            </ul>

            <!-- <a href="http://wpa.qq.com/msgrd?v=3&amp;uin=123123&amp;site=qq&amp;menu=yes" class="contact-us-btn">
            联系我们
          </a> -->
          </div>
          <div class="intro-other">
            <div class="do-web">
              <div class="intro-title">
                <i class="title-icon"></i>
                <span>域名与网站</span>
              </div>
              <ul class="intro-list clearfix">
                <li>
                  <a href="javascript:;">
                    <i class="p-icon icon iconfont">&#xe6cf;</i>
                    <div class="intro-info">
                      <div class="main-title hot">云虚拟主机</div>
                      <div class="subtitle">基于云计算的虚拟主机服务</div>
                    </div>
                  </a>
                </li>
                <li class="private">
                  <a href="javascript:;">
                    <i class="p-icon icon iconfont">&#xe6cc;</i>
                    <div class="intro-info">
                      <div class="main-title private">域名注册</div>
                      <div class="subtitle">提供五星级的域名注册服务</div>
                    </div>
                  </a>
                </li>
                <li class="private">
                  <a href="javascript:;">
                    <i class="p-icon icon iconfont">&#xe6c9;</i>
                    <div class="intro-info">
                      <div class="main-title private">网站备案</div>
                      <div class="subtitle">免费、快速、便捷的备案服务</div>
                    </div>
                  </a>
                </li>
                <li class="private">
                  <a href="javascript:;">
                    <i class="p-icon icon iconfont">&#xe6c5;</i>
                    <div class="intro-info">
                      <div class="main-title private">CDN加速</div>
                      <div class="subtitle">智能调度的内容分发服务</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div class="sec-service">
              <div class="intro-title">
                <i class="title-icon"></i>
                <span>安全服务</span>
              </div>
              <ul class="intro-list">
                <li class="private">
                  <a href="javascript:;">
                    <i class="p-icon icon iconfont">&#xe6ce;</i>
                    <div class="intro-info">
                      <div class="main-title">云帮手</div>
                      <div class="subtitle">提供跨云一站式云服务器管理</div>
                    </div>
                  </a>
                </li>
                <li class="private">
                  <a href="javascript:;">
                    <i class="p-icon icon iconfont">&#xe6d2;</i>
                    <div class="intro-info">
                      <div class="main-title private">SSL证书</div>
                      <div class="subtitle">提供一站式的证书部署系统</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div class="idc-service">
              <div class="intro-title">
                <i class="title-icon"></i>
                <span>IDC服务</span>
              </div>
              <ul class="intro-list">
                <li class="private">
                  <a href="/idc/">
                    <i class="p-icon icon iconfont">&#xe6cd;</i>
                    <div class="intro-info">
                      <div class="main-title private">服务器托管</div>
                      <div class="subtitle">安全贴心高品质的托管服务</div>
                    </div>
                  </a>
                </li>
                <li class="private">
                  <a href="javascript:;">
                    <i class="p-icon icon iconfont">&#xe6c8;</i>
                    <div class="intro-info">
                      <div class="main-title private">裸金属</div>
                      <div class="subtitle">高性能安全隔离物理集群服务</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--产品 end-->
    <!-- 资质 start-->
    <div class="index-honor">
      <div class="container">
        <div class="module-title">
          提供<span>安全合规</span>的云计算服务
          <p>权威认证的云计算服务，充分保障您的业务实践与业务安全</p>
        </div>
        <ul>
          <li>
            <a>
              <i class="img1"></i>
              <h5>IDC/云计算资质</h5>
            </a>
          </li>
          <li>
            <a>
              <i class="img2"></i>
              <h5>CNNIC</h5>
            </a>
          </li>
          <li>
            <a>
              <i class="img7"></i>
              <h5>中国移动</h5>
            </a>
          </li>
          <li>
            <a>
              <i class="img3"></i>
              <h5>中国电信</h5>
            </a>
          </li>
          <li>
            <a>
              <i class="img5"></i>
              <h5>中国联通</h5>
            </a>
          </li>
          <li>
            <a>
              <i class="img4"></i>
              <h5>软件著作权</h5>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!--资质 end-->

    <!--优势-->

    <!--优势 end-->
    <div class="map-service">
      <div class="cloud-product map-product">
        <!-- <p class="cloud-product-title"><span>您的业务可以遍布世界各地</span></p> -->
        <div class="module-title">
          您的业务可以<span>遍布</span>世界各地
          <p>网络拉近了人类的距离，云计算推动了文明的新高度</p>
        </div>
      </div>
      <div class="auto map-service-wrapper">
        <div class="map-service-box clearfix">
          <div class="world-map-wrapper">
            <div class="world-map">
              <div class="region-list postition-1">
                <div class="area-box">
                  <span class="dot"></span><span class="pulse"></span>
                </div>
              </div>
              <div class="region-list postition-2 underline-node">
                <div class="area-box">
                  <span class="dot"></span><span class="pulse"></span>
                </div>
                <div class="show-regin"></div>
              </div>
              <div class="region-list postition-3">
                <div class="area-box">
                  <span class="dot"></span><span class="pulse"></span>
                </div>
              </div>
              <div class="region-list postition-4">
                <div class="area-box">
                  <span class="dot"></span><span class="pulse"></span>
                </div>
              </div>
              <div class="region-list postition-5">
                <div class="area-box">
                  <span class="dot"></span><span class="pulse"></span>
                </div>
              </div>
              <div class="region-list active postition-6 online-node">
                <div class="area-box">
                  <span class="dot"></span><span class="pulse"></span>
                </div>
                <div class="show-regin"></div>
              </div>
              <div class="region-position-introduce postition-introduce-6">
                华北
              </div>
              <div class="region-list active postition-7 online-node">
                <div class="area-box">
                  <span class="dot"></span><span class="pulse"></span>
                </div>
                <div class="show-regin"></div>
              </div>
              <div class="region-position-introduce postition-introduce-7">
                华东
              </div>
              <div class="region-list active postition-8 online-node">
                <div class="area-box">
                  <span class="dot"></span><span class="pulse"></span>
                </div>
                <div class="show-regin"></div>
              </div>
              <div class="region-position-introduce postition-introduce-8">
                香港
              </div>
              <div class="region-list active postition-9 online-node">
                <div class="area-box">
                  <span class="dot"></span><span class="pulse"></span>
                </div>
                <div class="show-regin"></div>
              </div>
              <div class="region-position-introduce postition-introduce-9">
                华南
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--解决方案 start-->
    <div class="cloud-product-box solution-bgcolor border-box">
      <div class="cloud-product">
        <div class="module-title" style="margin-bottom: 20px">
          蘑菇云体验站<span>解决</span>方案
          <p>根据行业特性，为您提供高可用、安全的解决方案</p>
        </div>
      </div>
      <div class="solution-box-wraper">
        <div class="auto solution-box">
          <a href="#a_null" class="arr-btn arr-btn-left"
            ><i class="arr-left"></i
          ></a>
          <a href="#a_null" class="arr-btn arr-btn-right"
            ><i class="arr-right"></i
          ></a>
          <div class="solution-list">
            <ul id="solutionList">
              <li class="active solution-bg-li01">
                <div class="li-bgcolor opa-5"></div>
                <i class="cloud-icon"></i>
                <p class="solution-title">网站云解决方案</p>
                <p class="solution-intro">
                  网站云为企业及开发者提供灵活弹性自动化的基础IT设施建设、按需付费的服务模式及成本的运维服务体系，帮助客户转型，推动企业核心业务创新发展。
                </p>
                <a href="javascript:;" target="_blank">查看详情</a>
              </li>
              <li class="solution-bg-li02">
                <div class="li-bgcolor opa-8"></div>
                <i class="finance-icon"></i>
                <p class="solution-title">金融云解决方案</p>
                <p class="solution-intro">
                  金融云为客户提供量身定制的云计算服务，IT硬件零投入，云设施运维零维护，高品质保障的售后服务机制，帮助金融用户高效应用云计算服务，是您互联网转型的首选。
                </p>
                <a href="javascript:;" target="_blank">查看详情</a>
              </li>
              <li class="solution-bg-li03">
                <div class="li-bgcolor opa-5"></div>
                <i class="mobile-icon"></i>
                <p class="solution-title">移动云解决方案</p>
                <p class="solution-intro">
                  移动云应用虚拟化系统为客户提供最佳的应用性能及灵活的应用虚拟化服务，帮助客户实现手机、平板电脑等移动设备安全顺畅地访问服务器上各种应用软件。
                </p>
                <a href="javascript:;" target="_blank">查看详情</a>
              </li>
              <li class="solution-bg-li04">
                <div class="li-bgcolor opa-8"></div>
                <i class="busin-icon"></i>
                <p class="solution-title">电商云解决方案</p>
                <p class="solution-intro">
                  电商云帮助电商客户快速实现平台搭建、节约成本、应对业务高并发、强化安全防护能力，助力电商客户快速实现金融创新及业务增收的目标。
                </p>
                <a href="javascript:;" target="_blank">查看详情</a>
              </li>
              <li class="solution-bg-li05">
                <div class="li-bgcolor opa-5"></div>
                <i class="game-icon"></i>
                <p class="solution-title">游戏云解决方案</p>
                <p class="solution-intro">
                  游戏云为客户游戏开发、游戏运营提供专属服务集群；多场景多类型的游戏部署解决方案，同时提供尊贵VIP售后服务，为客户游戏稳定运行提供基石。
                </p>
                <a href="javascript:;" target="_blank">查看详情</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--解决方案 end-->

    <!--服务平台 end-->

    <!--新闻动态-->
    <div class="index-news">
      <div class="container">
        <div class="module-title">
          蘑菇云体验站<span>新闻</span>动态
          <p>
            为您提供最新行业资讯、活动公告、产品发布，汇聚最前沿最流行的云计算技术
          </p>
        </div>
      </div>
    </div>
    <!--新闻动态 end-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import NavHeader from "./../components/nav-header.vue";
export default {
  name: "banner",
  components: { NavHeader, Swiper, SwiperSlide },
  data() {
    return {
      activityImg: "",
      swiperOption: {
        speed: 900,
        autoplay: {
          delay: 3000, //1秒切换一次
        },
        loop: false,//停止循环
        loopPreventsSlide: true, //默认true，阻止
        effect: "cube",
        cubeEffect: {
          slideShadows: false,
          shadow: false,
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      slideList: [
      
      ],
      mgyBannerPageParam: {
        pageIndex: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    backImg() {
      if (this.activityImg) {
        return `background: url(${this.activityImg}) no-repeat center;`;
      }
    },
  },
  created() {
    this.getBanner();
  },
  methods: {
    getBanner() {
      this.$http
        .post("/mgyBanner/getPageList", this.mgyBannerPageParam)
        .then((res) => {
          this.slideList = res.data.data.records
          console.log(this.slideList)
        });
    },
    goHost() {
      this.$router.push("/host");
    },
  },
};
</script>
<style scoped src="../assets/css/index-new.css" >

</style>
<style scoped lang="scss">
.bannerCss{
  .act-img {
	position: relative;
	text-align: center;
	height: 400px;
  .main-title
{
   top:175px;
  font-size:40px;
	color: #fff;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}
.main-title-desc{
   top:225px;
  font-size:26px;
	color: #fff;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}

}
.swiper-container {
  height: 500px;
  .swiper-button-prev {
    left: 200px;
  }
  .swiper-button-next {
    right: 200px;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .swiper-cube-shadow {
    opacity: 1;
  }
}

.imgTitle{
  width:100%;
  height:100%;
}
.act-banner-img {
	position: relative;
	text-align: center;
	height: 500px;
}
}
</style>