import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import Order from '../views/Order.vue'
import Register from '../views/Register.vue'
import Verify from '../views/Verify.vue'
import Login from '../views/Login.vue'
import RefreshPassword from '../views/refreshPassword.vue'
import Banner from '../views/Banner.vue'
import Host from '../views/Host.vue'
import Citys from '../views/citys.vue'
import Pay from '../views/Pay.vue'
import Transation from '../views/Transation.vue'
import Contral from '../views/Contral.vue'
import Product from '../views/Product.vue'
import MyProduct from '../views/MyProduct.vue'
import Topay from '../views/toPay.vue'
import My from '../views/My.vue'
import Help from '../views/Help.vue'
import About from '../views/about.vue'
import Pact from '../views/pact.vue'
import shop from '../views/shop.vue'
import security from '../views/Security.vue'
import RealName from '../views/realName.vue'
import Alipay from '../views/alipay.vue'


Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect:'/host',
    children:[{
      path:'/index',
      name:'index',
      component:Index
    },{
      path:'/register',
      name:'register',
      component:Register
    },{
      path:'/verify',
      name:'verify',
      component:Verify
    },{
      path:'/refresh',
      name:'refresh',
      component:RefreshPassword
    },{
      path:'/banner',
      name:'banner',
      component:Banner
    },{
      path:'/host',
      name:'host',
      component:Host
    },
    {
      path:'/citys',
      name:'citys',
      component:Citys
    },{
      path:'/toPay',
      name:'toPay',
      component:Topay
    },{
      path:'/product',
      name:'product',
      component:Product
    },{
      path:'/help',
      name:'help',
      component:Help
    }
    ,{
      path:'/about',
      name:'about',
      component:About
    }
    ,{
      path:'/pact',
      name:'pact',
      component:Pact
    },{
      path:'/shop',
      name:'shop',
      component:shop
    },{
      path:'/security',
      name:'security',
      component:security
    }]
  }
  ,
  {
    path:'/login',
    name:'login',
    component:Login
  },
  {
    path:'/contral',
    name:'contral',
    redirect:'/myProduct',
    component:Contral,
    children:[{
      path:'/pay',
      name:'pay',
      component:Pay
    },
    {
      path:'/transation',
      name:'transation',
      component:Transation
    },
    {
      path:'/myProduct',
      name:'myProduct',
      component:MyProduct
    },{
      path:'/order',
      name:'order',
      component:Order
    },
    {
      path:'/me',
      name:'me',
      component:My
    },
    {
      path:'/realName',
      name:'realName',
      component:RealName
    }
    ]
  },
  {
    path:'/alipay',
    name:'alipay',
    component:Alipay
  }
]
let router = new VueRouter({ routes })
// 全局前置守卫
router.beforeEach((to, from, next) => {
	// 获取token
	let token = window.localStorage.getItem('token')
	let user = window.localStorage.getItem('user')
	if(token){ // 已登录
		next();
	} else {
		// 未登陆
		if(to.path === '/me'){
      Vue.prototype.$message.error('请先登录');
      return  next({ path:'/login' })
		}else if(to.path === '/order'){
      return  next({ path:'/login' })
    }
    else if(to.path === '/pay'){
      return  next({ path:'/login' })
    }
    else if(to.path === '/myProduct'){
      return  next({ path:'/login' })
    }
    else{
      next();
    }
	}
})


export default router
