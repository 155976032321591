import Vuex from 'vuex'
import Vue from 'vue'
import '../plugins/axios'
Vue.use(Vuex)
export default new Vuex.Store({
	state: {
		user: '',
		token: ''
	},
	mutations: {
		// 初始化用户信息
		initUser(state) {
			let user = window.localStorage.getItem('user')
			if (user) {
				state.user = JSON.parse(user)
				state.token = state.user.token
			}
		},
		// 登录
		xLogin(state, user) {
			// 保存登录状态
			state.user = user
			state.token = user.token
			// 存储到本地存储
			window.localStorage.setItem('user', JSON.stringify(state.user))
			window.localStorage.setItem('token', state.token)
		},
		updata(state, user){
			state.user = user
			window.localStorage.setItem('user', JSON.stringify(state.user))
		},
		loginOut(state){
			state.user = ''
			state.token = ''
			console.log('134')
			window.localStorage.clear();
		}
	},
	actions: {
		// 更新用户信息
		updateUser({ state, commit }) {
			axios.get('/mgyUser/info').then(result => {
				// 赋值
				console.log(result)
				commit('updata', result.data.data)
			})
		}
	},
	modules: {
	}
})