<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <img
          @click="goProduct"
          src="../assets/picture/default-logo.png"
          alt=""
        />
        <span>控制台</span>
      </div>
      <el-button type="info" @click="logout">退出</el-button>
    </el-header>
    <!-- 页面主体区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <!-- 侧边栏菜单区域 -->
        <el-menu
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409EFF"
          unique-opened
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          :default-active="activePath"
        >
          <!-- 一级菜单 -->
          <el-menu-item
          :default-active="activePath"
            @click="goPath(item.index)"
            :index="item.index + ''"
            v-for="item in menulist"
            :key="item.id"
          >
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="item.icon"></i>
              <!-- 文本 -->
              <span>{{ item.authName }}</span>
            </template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主体 -->
      <el-main>
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      // 左侧菜单数据
      menulist: [
        {
          id: 103,
           index:'/myProduct',
          authName: "我的产品",
          icon: "el-icon-money",
        },
        {
          id: 101,
           index:'/order',
          authName: "我的订单",
          icon: "el-icon-folder-checked",
        },
          {
          id: 125,
           index:'/me',
          authName: "充值",
          icon: "el-icon-user-solid",
        },
        {
          id: 102,
           index:'/pay',
          authName: "充值记录",
          icon: "el-icon-bank-card",
        },
        {
          id: 177,
          index:'/transation',
          authName: "交易明细",
          icon: "el-icon-bank-card",
        },
        {
          id: 111,
           index:'/realName',
          authName: "实名认证",
          icon: "el-icon-bank-card",
        },
      ],
      // 是否折叠
      isCollapse: false,
      // 被激活的链接地址
      activePath: "/myProduct",
    };
  },
  created() {
    // this.activePath = "/myProduct"
     if( window.localStorage.getItem('activePath')){
       this.activePath = window.localStorage.getItem('activePath')
     }
  },
  methods: {
    goPath(e) {
      console.log(e)
        this.activePath = e
       window.localStorage.setItem('activePath', e)
    },
    logout() {
      window.localStorage.clear();
      this.$router.push("/login");
    },
    // 获取所有的菜单
    // async getMenuList() {
    //   const { data: res } = await this.$http.get('menus')
    //   if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
    //   this.menulist = res.data
    //   console.log(res)
    // },
    // 点击按钮，切换菜单的折叠与展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    goProduct() {
      this.$router.push("/host");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/global.css";
.home-container {
  height: 100%;
}
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
}

.el-aside {
  background-color: #333744;
  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #eaedf1;
}

.iconfont {
  margin-right: 10px;
}

.toggle-button {
  background-color: #4a5064;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
</style>
