<template>
  <div>
    <nav-header></nav-header>
    <div class="autowidth main-body getpassword getpassword-index">
      <div class="password-title">
        找回密码
      </div>
      <div class="form">
        <div class="submit-box" style="margin-top:-20px">
          <div class="row-item">
            <span>登录账号</span>
            <input
             v-model="userUpdatePasswordParam.account"
              name="username"
              placeholder="请输入注册手机号"
              autocomplete="off"
              id="username"
              class="input input-300"
            />
          </div>

          <div class="register-item-box" style="margin-top:30px">
            <div class="register-item">
              <label
                >短信验证码<span>*</span
                ><input
                  v-model="userUpdatePasswordParam.code"
                  type="text"
                  name="mobilecode"
                  id="mobilecode"
                  maxlength="6"
                  placeholder="请输入短信验证码"
                />
                <div id="captchaTwo"></div>
                <input
                  v-show="showTime"
                  @click='closeDialog("open")'
                  type="button"
                  class="send-captcha"
                  v-model="text"
                />
                <input
                  v-if="!showTime"
                  type="button"
                  class="send-captcha"
                  v-model="s"
                />
              </label>
            </div>
            <div id="mobilecodeTip"></div>
          </div>
            <!-- 编辑界面 -->
            <el-dialog :title="title" :visible.sync="systemCodeVisible" width="20%" @click='closeDialog("close")'>

              <div class="block" v-for="fit in fits" :key="fit">
                <span class="demonstration"></span>
                <el-image
                    style="width: 100px; height: 100px"
                    :src="verifyUrl"
                    :fit="fit"
                    @click="refresh"
                ></el-image>
              </div>
              <el-input v-model="userUpdatePasswordParam.verifyCode" placeholder="请输入验证码"></el-input>

              <div slot="footer" class="dialog-footer">
                <el-button size="small" @click='closeDialog("close")'>取消</el-button>
                <el-button size="small" type="primary" class="title" @click='getCode'>确定</el-button>
              </div>
            </el-dialog>
          <div class="register-item-box">
            <div class="register-item">
              <label
                >设置密码<span>*</span
                ><input
                  v-model="userUpdatePasswordParam.password"
                  type="password"
                  name="password"
                  id="password"
                  maxlength="20"
                  placeholder="请输入密码"
              /></label>
            </div>
            <div id="passwordTip"></div>
          </div>
          <div class="register-item-box">
            <div class="register-item">
              <label
                >确认密码<span>*</span
                ><input
                  v-model="password"
                  type="password"
                  name="password2"
                  id="password2"
                  maxlength="20"
                  placeholder="请再次填写密码"
              /></label>
            </div>
            <div id="password2Tip"></div>
          </div>
          <div class="row-btn" style="margin-top:-20px" @click="goRegister">
            <input type="button" value="验证账号" class="submit next-step" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import NavHeader from "./../components/nav-header.vue";
export default {
  name: "refresh",
  components: {
    NavHeader,
  },
  data() {
    return {
      text: "获取验证码",
      title: "系统验证码",
      showTime: true,
      setTime: null,
      s: 60,
      systemCodeVisible: false,
      fits: ['fill'],
      verifyUrl: "",
      getSmsCodeParam:{
        account: "",
        verifyCode: "",
        uuid: "",
      },
      userUpdatePasswordParam: {
        account:"",
        verifyCode: "",
        password: "",
        uuid: "",
        code: "",
      },
      password: "",
    };
  },
  created() {
    this.$http
        .get('/verifyCode/getSystemCode')
        .then((res) => {
          this.verifyUrl = res.data.image
          this.userUpdatePasswordParam.uuid = res.data.uuid
        });
  },
  methods: {
    ...mapMutations(["loginOut"]),
    getCode() {
      if (this.s != 60) {
        return this.$message.error("请稍后再试");
      }
      let phoneStatus = this.checkPhone(this.userUpdatePasswordParam.account);
      if (!phoneStatus) {
        this.$message.error("请输入正确手机号");
      }
      this.$http
          .post(`/sms/getSmsCode`,this.userUpdatePasswordParam)
          .then((res) => {
            if(res.data.code == 200){
              this.$message.success('获取验证码成功')
              this.systemCodeVisible = false
            }else if(res.data.code == 5109){
              this.s = 1
              this.$message.error("系统验证码输入错误");
            }else if(res.data.code == 5110){
              this.s = 1
              this.$message.error("未检测到系统验证码");
            }else {
              this.$message.error("获取验证码失败");
            }
            this.setTime = setInterval(() => {
              this.countDown();
            }, 1000);
          });
    },
    // 关闭编辑、增加弹出框
    closeDialog(dialog) {
      if (dialog == 'close') {
        this.systemCodeVisible = false
      }else if (dialog == 'open'){
        this.systemCodeVisible = true
      }
    },
    refresh() {
      this.$http
          .get('/verifyCode/getSystemCode')
          .then((res) => {
            this.verifyUrl = res.data.image
            this.userUpdatePasswordParam.uuid = res.data.uuid
          });
    },
    countDown() {
      if (this.s > 0) {
        this.showTime = false;
        this.s -= 1;
      } else if (this.s == 0) {
        this.handclick();
        this.showTime = true;
      }
    },
    handclick() {
      console.log(this.showTime);
      this.text = "重新获取";
      this.s = 60;
      clearInterval(this.setTime);
      this.setTime = null;
    },
    goRegister() {
      if (this.userUpdatePasswordParam.password != this.password) {
        return this.$message.error("两次输入的密码不一致");
      }
      this.$http
        .post("/mgyUser/updatePassword", this.userUpdatePasswordParam)
        .then((res) => {
          this.userUpdatePasswordParam.password = "";
          this.password = "";
          console.log(res);
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.loginOut()
            this.$router.push("/login");
          } else {
            this.$message.error(res.data.data);
          }
        });
    },
    goLogin() {
      this.$router.push("/login");
    },
  },
};
</script>


<style>
@import "../assets/css/password.css";
</style>
